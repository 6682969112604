import lodash from 'lodash'
import moment from 'moment-timezone'

import PropTypes from 'prop-types'

import React, { Component } from 'react'

class RoomItem extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    room: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    onPickUp: PropTypes.func,
    onHungUp: PropTypes.func,
    platform: PropTypes.string,
    operator_called_at: PropTypes.object,
    timezone: PropTypes.string,
    isFetching: PropTypes.bool,
  }

  onClick = () => {
    const { room, onClick } = this.props
    onClick(room.id)
  }

  onPickUp = e => {
    e.stopPropagation()
    const { room, onPickUp } = this.props
    onPickUp(room.id)
  }

  onHungUp = e => {
    e.stopPropagation()
    const { t } = this.context
    const { room, onHungUp } = this.props
    if (window.confirm(t('operator.messages.hungUpConfirmation'))) {
      onHungUp(room.id)
    }
  }

  formatTimestamp = timestamp => {
    const { t } = this.context
    const baseTime = moment.utc()
    const { timezone } = this.props
    const days = baseTime.diff(timestamp, 'days')
    const hours = baseTime.diff(timestamp, 'hours')
    const minutes = baseTime.diff(timestamp, 'minutes') % 60
    const seconds = baseTime.diff(timestamp, 'seconds')

    if (days > 0 && timezone) return timestamp.tz(timezone).format('YYYY/MM/DD HH:mm:ss')
    if (hours > 0) return t('operator.timeFormat.hours', { hours: hours, minutes: minutes })
    if (minutes > 0) return t('operator.timeFormat.minutes', { minutes: minutes })
    if (seconds >= 5) return t('operator.timeFormat.seconds', { seconds: seconds })
    return t('operator.timeFormat.now')
  }

  render() {
    const session = this.context.store.getState().session
    const { t } = this.context
    const { room, selected, platform, operator_called_at, isFetching } = this.props
    const timestampString = this.formatTimestamp(operator_called_at)
    const icons = {
      simulator: 'webpage',
      web: 'world',
      line: 'smartphone',
      line_works: 'smartphone',
      line_works_v2: 'smartphone',
      slack: 'postcard',
      azure_bot_service: 'share',
      skype_for_business: 'bolt',
      direct_line: 'target',
      mobilus: 'headset',
      api_v1: 'hammer',
      hangouts: 'postcard-multiple',
    }
    const operators = room.operators.map(operator => operator.name).join(', ')

    let button = null
    if (room.operator_state === 'assigned') {
      if (lodash.find(room.operators, { id: session.id })) {
        button = (
          <button className="dm-btn btn btn-default pull-right" onClick={this.onHungUp} disabled={isFetching}>
            {t('operator.hungUp')}
          </button>
        )
      }
    } else {
      button = (
        <button className="dm-btn btn btn-default pull-right" onClick={this.onPickUp} disabled={isFetching}>
          {t('operator.pickUp')}
        </button>
      )
    }

    return (
      <li className={`room ${room.operator_state} ${selected && 'selected'}`} onClick={this.onClick}>
        <div>
          <span className={`icon icon-basic-${icons[platform]}`} />
          <span className="room-id">{room.id}</span>
          {room.unread > 0 && <i className="dm-icon-notification">{room.unread || 0}</i>}
          {room.operator_state === 'calling' && <i className="dm-icon-notification">&nbsp;</i>}
          {button}
          <div className="clearfix" />
        </div>
        <div className="info">
          <small>{t('operator.client') + room.client.name}</small>
        </div>
        {room.operator_state === 'assigned' && (
          <div className="info pt-0">
            <small className="operator" title={operators}>
              {t('operator.operator') + operators}
            </small>
          </div>
        )}
        <div className="info">
          <small className="timestamp">{timestampString}</small>
        </div>
      </li>
    )
  }
}

export default RoomItem
