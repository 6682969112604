import lodash from 'lodash'
import moment from 'moment'
import uuid from 'uuid'

import { saveMessages, restoreMessages } from '../helpers/persistMessages'

const initialState = {
  applicationToken: undefined,
  rooms: {},
  currentChannel: undefined,
  currentRoom: undefined,
  messages: [],
  status: 'loading',
  features: {},
  isAutoScroll: true,
  suggestion: {
    candidates: [],
  },
  isBotTyping: false,
  preventBotTypingMessage: false,
  isOperatorTyping: false,
  noticeMode: {},
  speaker: {},
}

const reducers = {}

reducers['SET_APPLICATION_TOKEN'] = (state, action) => {
  return { ...state, applicationToken: action.applicationToken }
}

reducers['SET_CHAT_STATUS'] = (state, action) => {
  return { ...state, status: action.status }
}

reducers['RECEIVE_ROOMS'] = (state, action) => {
  const rooms = action.rooms.reduce((hash, room) => {
    hash[room.id] = { ...state.rooms[room.id], ...room }
    return hash
  }, {})
  return { ...state, rooms: { ...state.rooms, ...rooms } }
}

reducers['RECEIVE_ROOM'] = (state, action) => {
  if (!action.room) return state
  let rooms = { ...state.rooms }
  rooms[action.room.id] = { ...rooms[action.room.id], ...action.room }
  return {
    ...state,
    rooms,
  }
}

reducers['REPLACE_ROOMS'] = (state, action) => {
  const rooms = action.rooms.reduce((hash, room) => {
    hash[room.id] = { ...state.rooms[room.id], ...room }
    return hash
  }, {})
  return { ...state, rooms }
}

reducers['SET_CURRENT_CHANNEL'] = (state, action) => {
  // clear messages when change channel from connected one
  const isClearMessages = state.currentChannel && state.currentChannel !== action.channel_uuid
  return {
    ...state,
    currentChannel: action.channel_uuid,
    messages: isClearMessages ? [] : state.messages,
  }
}

reducers['SET_FEATURES'] = (state, action) => {
  return {
    ...state,
    features: action.features,
  }
}

reducers['UPDATE_CURRENT_CHANNEL'] = (state, action) => {
  return {
    ...state,
    features: { ...state.features, ...action.features },
  }
}

reducers['SET_CURRENT_ROOM'] = (state, action) => {
  return {
    ...state,
    currentRoom: lodash.find(state.rooms, { id: action.room.id }),
    messages: [],
  }
}

reducers['UNSET_CURRENT_ROOM'] = (state, _action) => {
  return {
    ...state,
    currentRoom: undefined,
    messages: [],
  }
}

const disableOldFeedbackMessagesInSameThread = messages => {
  const removedMessages = []
  const threadUuids = []

  lodash.forEachRight(messages, message => {
    if (message.type === 'request_feedback') {
      if (!lodash.includes(threadUuids, message.content.threadUUID)) {
        threadUuids.push(message.content.threadUUID)
      } else {
        message.content.disabled = true
      }
    }
    removedMessages.unshift(message)
  })

  return removedMessages
}

const mergeMessages = messages => {
  const mergedMessages = []
  const messageByUUID = {}
  messages.forEach(message => {
    if (message.type === 'response_feedback') {
      return
    }
    if (lodash.includes(['request_feedback', 'form'], message.type)) {
      const originalMessage = messageByUUID[message.content.replyToUUID]
      if (originalMessage) {
        if (message.content.replyToUUID) {
          originalMessage.content = message.content
          originalMessage.app_language_content = message.app_language_content
        }
        return
      }
    }

    mergedMessages.push(message)

    const messageUUID = (message.content || {}).replyToUUID || message.uuid
    messageByUUID[messageUUID] = message
  })
  return disableOldFeedbackMessagesInSameThread(mergedMessages)
}

reducers['RECEIVE_MESSAGES'] = (state, action) => {
  if (state.currentChannel && action.channel_uuid && state.currentChannel !== action.channel_uuid)
    return state

  const unread = !lodash.some(action.messages, { sender_type: 'client' })
  const messages = action.messages.map(message => ({
    uuid: message.uuid || uuid.v4(),
    sender_uid: message.sender_uid,
    sender_type: message.sender_type,
    sender_name: message.sender_name,
    type: message.type,
    content: message.content,
    app_language_content: message.app_language_content,
    timestamp: moment(message.timestamp),
    prefetched: message.prefetched,
    unread: unread,
  }))

  //  Keep prefetched messages before join message has been processed in background API
  //  TODO: Implement any method to get finish of processing in background API.
  //        Current logic with multiple initial messages may display partial messages only
  //        because fetching messages interrupt multiple initial messages.
  const hasResponse = lodash.some(action.messages, { sender_type: 'bot' })
  const newMessages = lodash.cloneDeep(
    lodash.unionBy(
      messages,
      lodash.filter(state.messages, message => !hasResponse || !message.prefetched),
      'uuid'
    )
  )
  return {
    ...state,
    messages: mergeMessages(
      lodash.sortBy(newMessages, message => {
        if (moment.isMoment(message.timestamp)) {
          return message.timestamp.toISOString()
        }
        return message.timestamp
      })
    ),
  }
}

const updateMessageByUUID = (messages, uuid, message) => {
  const index = lodash.findIndex(messages, { uuid })
  if (index === -1) return messages

  return [
    ...messages.slice(0, index),
    {
      ...messages[index],
      ...message,
    },
    ...messages.slice(index + 1),
  ]
}

reducers['STORE_MESSAGE'] = (state, action) => {
  //  Ignore response_feedback message
  if (action.message.type === 'response_feedback') return state

  const messageIndex = lodash.findIndex(state.messages, { uuid: action.message.uuid })
  if (messageIndex !== -1) {
    //  Overwrite temporary client message
    const message = {
      timestamp: moment(action.message.timestamp),
      channel_uuid: action.message.channel_uuid,
      sender_uid: action.message.sender_uid,
      sender_name: action.message.sender_name,
      sender_type: action.message.sender_type,
      type: action.message.type,
      content: action.message.content,
      app_language_content: action.message.app_language_content,
      temporary: action.message.temporary,
    }
    const nextMessages = updateMessageByUUID(state.messages, action.message.uuid, message)
    return {
      ...state,
      messages: lodash.orderBy(nextMessages, 'timestamp'),
    }
  }

  let nextMessages = lodash.clone(state.messages)

  if (
    lodash.includes(['request_feedback', 'form'], action.message.type) &&
    action.message.content.replyToUUID
  ) {
    //  Overwrite request feedback message and form message
    const message = {
      content: action.message.content,
      app_language_content: action.message.app_language_content,
    }
    nextMessages = updateMessageByUUID(nextMessages, action.message.content.replyToUUID, message)
  } else {
    //  Append temporary client message or server message
    const latestMessage = lodash.maxBy(nextMessages, 'timestamp')
    const timestamp = lodash.max([latestMessage?.timestamp, moment(action.message.timestamp)])
    nextMessages.push({
      uuid: action.message.uuid,
      timestamp: timestamp,
      channel_uuid: action.message.channel_uuid,
      sender_uid: action.message.sender_uid,
      sender_name: action.message.sender_name,
      sender_type: action.message.sender_type,
      type: action.message.type,
      content: action.message.content,
      app_language_content: action.message.app_language_content,
      temporary: action.message.temporary,
      unread: true,
    })
  }

  nextMessages = lodash.orderBy(nextMessages, 'timestamp')
  return {
    ...state,
    messages: disableOldFeedbackMessagesInSameThread(nextMessages),
  }
}

reducers['CLEAR_MESSAGES'] = (state, _action) => {
  return {
    ...state,
    messages: [],
  }
}

reducers['CLEAR_TEMPORARY_MESSAGES'] = (state, _action) => {
  const nextState = lodash.cloneDeep(state)
  lodash.remove(nextState.messages, message => message.temporary === true)
  return nextState
}

reducers['INCREMENT_UNREAD_MESSAGE'] = (state, action) => {
  const room = state.rooms[action.room.id]
  if (!room) return state

  //  Ignore received message while opening room
  if (state.currentRoom && state.currentRoom.id === room.id) return state

  const newRoom = { ...room, unread: (room.unread || 0) + 1 }
  const rooms = { ...state.rooms, [room.id]: newRoom }

  return {
    ...state,
    rooms: rooms,
  }
}

reducers['CLEAR_UNREAD_MESSAGE'] = (state, action) => {
  const room = state.rooms[action.room.id]
  if (!room) return state

  const rooms = { ...state.rooms, [room.id]: { ...room, unread: 0 } }
  return {
    ...state,
    rooms: rooms,
  }
}

reducers['START_BOT_TYPING'] = (state, _action) => {
  return {
    ...state,
    isBotTyping: true,
  }
}

reducers['STOP_BOT_TYPING'] = (state, _action) => {
  return {
    ...state,
    isBotTyping: false,
  }
}

reducers['PREVENT_BOT_TYPING_MESSAGE'] = (state, _action) => {
  return {
    ...state,
    preventBotTypingMessage: true,
  }
}

reducers['ALLOW_BOT_TYPING_MESSAGE'] = (state, _action) => {
  return {
    ...state,
    preventBotTypingMessage: false,
  }
}

reducers['START_OPERATOR_TYPING'] = (state, _action) => {
  return {
    ...state,
    isOperatorTyping: true,
  }
}

reducers['STOP_OPERATOR_TYPING'] = (state, _action) => {
  return {
    ...state,
    isOperatorTyping: false,
  }
}

reducers['UPDATE_UNREAD_STATUS'] = (state, _action) => {
  return {
    ...state,
    messages: lodash.map(state.messages, message => ({ ...message, unread: false })),
  }
}

reducers['SET_AUTO_SCROLL'] = (state, action) => {
  return {
    ...state,
    isAutoScroll: action.isAutoScroll,
  }
}

reducers['SET_CANDIDATES'] = (state, action) => {
  return {
    ...state,
    suggestion: {
      candidates: action.candidates,
      selectedIndex: null,
    },
  }
}

reducers['CLEAR_CANDIDATES'] = (state, _action) => {
  return {
    ...state,
    suggestion: {
      candidates: [],
      selectedIndex: null,
    },
  }
}

reducers['SELECT_PREV_CANDIDATE'] = (state, _action) => {
  const count = state.suggestion.candidates.length
  const current = state.suggestion.selectedIndex
  let next = null
  if (count > 0) {
    next = current == null ? count - 1 : (current - 1 + count) % count
  }

  return {
    ...state,
    suggestion: {
      ...state.suggestion,
      selectedIndex: next,
    },
  }
}

reducers['SELECT_NEXT_CANDIDATE'] = (state, _action) => {
  const count = state.suggestion.candidates.length
  const current = state.suggestion.selectedIndex
  let next = null
  if (count > 0) {
    next = current == null ? 0 : (current + 1) % count
  }

  return {
    ...state,
    suggestion: {
      ...state.suggestion,
      selectedIndex: next,
    },
  }
}

reducers['SELECT_CANDIDATE'] = (state, action) => {
  const count = state.suggestion.candidates.length
  return {
    ...state,
    suggestion: {
      ...state.suggestion,
      selectedIndex: Math.max(0, Math.min(count - 1, action.index)),
    },
  }
}

reducers['CHANGE_NOTICE_MODE'] = (state, action) => {
  return {
    ...state,
    noticeMode: {
      ...state.noticeMode,
      [action.applicationToken]: action.mode,
    },
  }
}

reducers['CHANGE_SPEAKER'] = (state, action) => {
  return {
    ...state,
    speaker: {
      ...state.speaker,
      [action.applicationToken]: action.speaker,
    },
  }
}

reducers['RESTORE_MESSAGES'] = (state, action) => {
  return {
    ...state,
    messages: restoreMessages(action.channel_uuid),
  }
}

const reducer = (state = initialState, action) => {
  if (reducers[action.type]) {
    const nextState = reducers[action.type](state, action)
    saveMessages(nextState)
    return nextState
  }
  return state
}

export default reducer
