import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchFaqPdfFiles = (token, filter = {}) => dispatch => {
  return request.get('/faq_pdf_files/', {
    token,
    dispatch,
    body: filter,
    target: 'faq_pdf_files',
    schema: Schemas.faqPdfFiles,
  })
}

export const fetchFaqPdfFile = (token, id) => dispatch => {
  return request.get(`/faq_pdf_files/${id}`, {
    token,
    dispatch,
    target: 'faq_pdf_files',
    schema: Schemas.faqPdfFile,
  })
}

export const updateFaqPdfFile = (token, body, id) => dispatch => {
  return request.put(`/faq_pdf_files/${id}`, {
    token,
    dispatch,
    body,
    target: 'faq_pdf_files',
    schema: Schemas.faqPdfFile,
  })
}

export const deleteFaqPdfFile = (token, id) => dispatch => {
  return request
    .delete(`/faq_pdf_files/${id}`, {
      token,
      dispatch,
      target: 'faq_pdf_files',
    })
    .then(() => dispatch(deleteCache('faq_pdf_files', id)))
}

export const importFaqPdfFile = (token, body) => dispatch => {
  return request.post('/faq_pdf_files/import', {
    token,
    dispatch,
    body,
    target: 'faq_pdf_files',
    schema: Schemas.faqPdfFile,
  })
}

export const deleteFaqPdfFileRevision = (token, faq_pdf_file_id, id) => dispatch => {
  return request.delete(`/faq_pdf_files/${faq_pdf_file_id}/revisions/${id}`, {
    token,
    dispatch,
    target: 'faq_pdf_files',
  })
}
