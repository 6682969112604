import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import darken from '../../containers/utils/colorConverter'

export class WebChatPreview extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    colors: PropTypes.object.isRequired,
    icon_url: PropTypes.string,
    enableFooterResetButton: PropTypes.bool,
    resetLabelInBalloon: PropTypes.string,
    enableFooterUndoButton: PropTypes.bool,
    undoLabelInBalloon: PropTypes.string,
    position: PropTypes.string,
    launcher_type: PropTypes.string,
    launcher_image_src: PropTypes.string,
    closer_type: PropTypes.string,
    isOpenLauncher: PropTypes.bool,
    isOpenChat: PropTypes.bool,
    toggleIsOpen: PropTypes.func,
    autoHideScrollbar: PropTypes.bool,
  }

  generateStyleTag() {
    const { colors } = this.props
    const sendButtonBgHoverColor = darken(colors.sendButton.bg, 20)

    let botFooterButtonBorder = 'none'
    if (colors.botBalloon && colors.botBalloon.bg === '#ffffff') {
      botFooterButtonBorder = 'solid 1px #9e9e9e'
    }

    let clientUndoButtonBorder = 'solid 1px #9e9e9e'
    if (colors.userBalloon && colors.userBalloon.bg !== '#ffffff') {
      clientUndoButtonBorder = 'none'
    }

    return (
      <style>
        {`
          {/* header */}
          .dm-chat-preview .dm-chat-header {background: ${colors.header.bg}; color: ${colors.header.font};}
          {/* background */}
          .dm-chat-preview .custom {background: ${colors.background.bg};}
          .dm-chat-preview .chat .timestamp {color: ${colors.background.font};}
          {/* bot balloon */}
          .dm-chat-preview .chat>.server .footer-border {background-color: ${colors.botBalloon.bg} !important;}
          .dm-chat-preview .chat>.server .content { background-color: ${colors.botBalloon.bg} !important; }
          .dm-chat-preview .chat>.server .content:before { border-top-color: ${colors.botBalloon.bg} !important; }
          .dm-chat-preview .chat>.server .content .footer-btn { border: ${botFooterButtonBorder}; }
          .dm-chat-preview .chat>.server.is-confirm .panel {border-color: ${colors.botBalloon.bg};}
          .dm-chat-preview .chat>.server.is-confirm .panel:before {border-top-color: ${colors.botBalloon.bg};}
          .dm-chat-preview .chat>.server.is-confirm .panel .panel-heading {
            background: ${colors.botBalloon.bg};
            border-color: ${colors.botBalloon.bg};
          }
          .dm-chat-preview .chat>.server.is-confirm .panel .panel-border {background: ${colors.botBalloon.bg};}
          .dm-chat-preview .chat>.server.is-confirm .panel .panel-footer {background: ${colors.botBalloon.bg};}
          .dm-chat-preview .chat>.server.is-confirm .panel .footer-btn {border: ${botFooterButtonBorder} !important;}
          .dm-chat-preview .chat>.server .content .text {color: ${colors.botBalloon.font};} 
          .dm-chat-preview .chat>.server .dm-panel.panel-default .panel-heading .title {color: ${colors.botBalloon.font};} 
          {/* user balloon */}
          .dm-chat-preview .chat>.client .footer-border { background-color: ${colors.userBalloon.bg} !important; }
          .dm-chat-preview .chat>.client .content { background-color: ${colors.userBalloon.bg} !important; }
          .dm-chat-preview .chat>.client .content:before { border-top-color: ${colors.userBalloon.bg} !important; }
          .dm-chat-preview .chat>.client .content .content-footer { background-color: ${colors.userBalloon.bg} !important; }
          .dm-chat-preview .chat>.client .content .panel-border { background-color: ${colors.userBalloon.bg} !important; }
          .dm-chat-preview .chat>.client .content .footer-btn {border: ${clientUndoButtonBorder};}
          .dm-chat-preview .chat>.client .content .text {color: ${colors.userBalloon.font};} 
          {/* sendButton */}
          .dm-chat-preview .dm-chat-footer .input-group-btn:nth-of-type(2) button {
            background: ${colors.sendButton.bg} !important;
            border-color: ${colors.sendButton.bg} !important;
          }
          .dm-chat-preview .dm-chat-footer .input-group-btn:nth-of-type(2) button:hover {
            background: ${sendButtonBgHoverColor} !important;
            border-color: ${sendButtonBgHoverColor} !important;
          }
          .dm-chat-preview .dm-chat-footer .input-group-btn:nth-of-type(2) button {color: ${colors.sendButton.font};}
          .dm-chat-preview .dm-chat-footer .input-group-btn:nth-of-type(2) button:hover {color: ${colors.sendButton.font}!important;}
        `}
      </style>
    )
  }

  renderLauncherImage() {
    const { launcher_image_src, isOpenLauncher, toggleIsOpen } = this.props
    const isOpen = isOpenLauncher ? 'is-open' : ''
    const no_cache_src = launcher_image_src.startsWith('https://')
      ? launcher_image_src + '?' + Date.now()
      : launcher_image_src
    return (
      <div className={`launcher ${isOpen}`} onClick={toggleIsOpen}>
        <img className="custom-image" src={no_cache_src} alt="" />
      </div>
    )
  }

  renderLauncherBalloonButton() {
    const { isOpenLauncher, toggleIsOpen } = this.props
    const isOpen = isOpenLauncher ? 'is-open' : ''
    return (
      <div className={`launcher ${isOpen}`} onClick={toggleIsOpen}>
        <div className="balloon-button" />
      </div>
    )
  }

  renderScriptButtons() {
    const { t } = this.context
    const { isOpenChat, toggleIsOpen } = this.props
    return (
      <div className="launcher">
        <input
          type="button"
          value={t('application.web.customize.preview.open')}
          onClick={event => {
            if (isOpenChat) return
            toggleIsOpen(event)
          }}
        />
        <input
          type="button"
          value={t('application.web.customize.preview.close')}
          onClick={event => {
            if (!isOpenChat) return
            toggleIsOpen(event)
          }}
        />
        <input
          type="button"
          value={t('application.web.customize.preview.toggle')}
          onClick={event => {
            toggleIsOpen(event)
          }}
        />
      </div>
    )
  }

  render() {
    const { t } = this.context
    const {
      title,
      theme,
      fontSize,
      icon_url,
      position,
      enableFooterResetButton,
      resetLabelInBalloon,
      enableFooterUndoButton,
      undoLabelInBalloon,
      launcher_type,
      closer_type,
      isOpenChat,
      autoHideScrollbar,
    } = this.props
    const isOpen = isOpenChat || position === 'position-frame' ? 'is-open' : ''

    const scrollbar = classnames({ autoHideScrollbar: autoHideScrollbar })

    const fontSizeClasses = {
      [-3]: 'x2-small',
      [-2]: 'x-small',
      [-1]: 'small',
      0: '',
      1: 'large',
      2: 'x-large',
      3: 'x2-large',
    }

    const closerClasses = {
      header: 'close-header',
      button: 'close-button',
    }

    let launcher = null
    if (position !== 'position-frame') {
      if (launcher_type === 'custom-image') launcher = this.renderLauncherImage()
      if (launcher_type === 'balloon-button') launcher = this.renderLauncherBalloonButton()
      if (launcher_type === 'none') launcher = this.renderScriptButtons()
    }

    return (
      <div
        className={`
          dm
          ${position ? position : 'position-right'}
          ${theme ? theme : 'theme-blue'}
          ${launcher_type ? launcher_type : 'title-header'}
          ${closerClasses[closer_type] || 'close-header'}
        `}
      >
        {launcher}
        {theme === 'custom' && this.generateStyleTag()}
        <div
          className={`dm-chat dm-chat-preview force-normal ${
            position !== 'position-frame' ? 'is-popup' : ''
          } device-unknown ${isOpen} ${fontSizeClasses[fontSize]}`}
        >
          {position !== 'position-frame' && (
            <div
              className="dm-chat-header"
              onClick={closer_type === 'header' || !isOpenChat ? this.props.toggleIsOpen : () => {}}
            >
              <button className="is-open-simulator" style={{ pointerEvents: 'none' }} />
              <span>{title}</span>
              <button
                className="close"
                onClick={closer_type === 'button' ? this.props.toggleIsOpen : () => {}}
              />
            </div>
          )}
          <div className="dm-chat-preview-iframe">
            <div className={`fit embedded dm-chat ${theme} ${fontSizeClasses[fontSize]} ${scrollbar}`}>
              {/* chat balloons */}
              <ul className="chat client is-preview">
                <li className="padding" />
                <li className="server">
                  <div className="body">
                    {icon_url ? (
                      <img id="icon" className="avator-bot-changed-icon" src={icon_url} alt="" />
                    ) : (
                      <span className="avator-bot" />
                    )}
                    <div className="balloon">
                      <div className="content">
                        <p className="text">
                          <span>{t('application.web.customize.preview.chat1')}</span>
                        </p>
                      </div>
                      <span className="message-footer">
                        <small className="timestamp">
                          {t('application.web.customize.preview.timestamp1')}
                        </small>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="client">
                  <div className="body">
                    <div className="balloon">
                      <div className="content">
                        <p className="text">{t('application.web.customize.preview.chat2')}</p>
                      </div>
                      <span className="message-footer">
                        <small className="timestamp">
                          {t('application.web.customize.preview.timestamp1')}
                        </small>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="unread unread-indicator">
                  <hr />
                  <span>{t('application.web.customize.preview.newMessages')}</span>
                  <hr />
                </li>
                <li className="is-confirm server">
                  <div className="body">
                    {icon_url ? (
                      <img id="icon" className="avator-bot-changed-icon" src={icon_url} alt="" />
                    ) : (
                      <span className="avator-bot" />
                    )}
                    <div className="balloon">
                      <div className="panel panel-default dm-panel choose">
                        <div className="panel-heading" title="choice">
                          <small className="title">
                            {t('application.web.customize.preview.chat3.title')}
                          </small>
                        </div>
                        <div className="panel-body">
                          <span>{t('application.web.customize.preview.chat3.body')}</span>
                        </div>
                        <div className="panel-border" />
                        <div className="btn-group btn-group-vertical">
                          <button type="button" className="btn" data-value="item1" data-label="item1">
                            {t('application.web.customize.preview.chat3.item1')}
                          </button>
                          <button type="button" className="btn" data-value="item2" data-label="item2">
                            {t('application.web.customize.preview.chat3.item2')}
                          </button>
                        </div>
                        {(enableFooterResetButton || enableFooterUndoButton) && (
                          <React.Fragment>
                            <div className="footer-border" />
                            <div className="panel-footer">
                              <div className="footer-btns-wrapper">
                                {enableFooterResetButton && (
                                  <button type="button" className="dm-btn btn footer-btn">
                                    {resetLabelInBalloon ||
                                      t('bot.advancedSettings.reset.labelInBalloon.default')}
                                  </button>
                                )}
                                {enableFooterUndoButton && (
                                  <button type="button" className="dm-btn btn footer-btn">
                                    {undoLabelInBalloon ||
                                      t('bot.advancedSettings.undo.labelInBalloon.default')}
                                  </button>
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                      <span className="message-footer">
                        <small className="unread">●</small>
                        <small className="timestamp">
                          {t('application.web.customize.preview.timestamp2')}
                        </small>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              {/* footer */}
              <div style={{ height: 'auto' }}>
                <div className="dm-chat-footer dm-chat-preview-footer">
                  <div className="input-group">
                    <span className="input-group-btn">
                      <button type="button" className="btn btn-default dm-btn toggle-menu ">
                        <img src="/image/icon_plus_black.png" alt="" />
                      </button>
                    </span>
                    <TextareaAutosize
                      type="text"
                      name="text"
                      className="form-control dm-form-control"
                      autoComplete="off"
                      minRows={1}
                      placeholder={t('embeddedChat.textField')}
                    />
                    <span className="input-group-btn">
                      <button type="button" className="btn btn-primary dm-btn">
                        {t('application.web.customize.preview.send')}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dm-chat-footer" />
        </div>
      </div>
    )
  }
}

WebChatPreview.defaultProps = {
  title: 'default title',
  theme: 'theme-blue',
  fontSize: 0,
}

export default WebChatPreview
