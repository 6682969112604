import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm, propTypes } from 'redux-form'

// components
import Loader from '../../components/common/Loader'
import TopicEditComponent from '../../components/topic/TopicEdit'
import Simulator from '../../containers/simulator/Simulator'
import Sidebar from '../../components/common/Sidebar'
import ScenarioOption from '../../components/scenario_option/ScenarioOption'

// actions
import { addNotice } from '../../actions/notice'
import { fetchBot } from '../../actions/bot'
import { fetchDomains } from '../../actions/domain'
import { fetchEntities } from '../../actions/entity'
import { fetchIntegrations } from '../../actions/integration'
import { fetchFaqs } from '../../actions/faq'
import { fetchFaqPdfFiles } from '../../actions/faq_pdf_file'
import { fetchTasks } from '../../actions/task'
import { fetchApplications } from '../../actions/application'
import { resetChannel } from '../../actions/embedded'
import { fetchTopics, fetchTopic, updateTopic, createTopic, deleteTopic } from '../../actions/topic'
import { fetchConditionGroups } from '../../actions/condition_group'
import { updateTableState } from '../../actions/table'

// helpers
import i18next from '../../middleware/i18n'
import Config from '../../helpers/config'
import {
  validateAction,
  validateActionForLine,
  sanitizeActions,
  collectVariables,
  convertSystemVariablesOfAction,
  getEntityName,
} from '../../helpers/actionHelper'
import { checkApplications } from '../../helpers/checkApplications'
import { uploadImageFileWithValidation } from '../../helpers/image'
import { isFetching } from '../../helpers/selector'
import { isPermitted } from '../../helpers/permission'
import cancelUrl, { isDirtyForm } from '../../helpers/cancelurl'

const validate = data => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.trim() === '') {
    errors.name = 'validate.required'
  } else if (data.name.length > 255) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  } else if (data.name.match(/^\[.*\]$/)) {
    errors.name = 'validate.reservedName'
  }

  errors.domains = lodash.map(data.domains, (domain, index) => {
    if ((!domain || !domain.name) && lodash.some(data.domains.slice(index + 1, 5))) {
      return 'validate.required'
    }

    if (domain && domain.name && domain.name.length > 40) {
      return { id: 'validate.exceededMaxLength', values: { length: 40 } }
    } else if (domain && domain.name && domain.name.match(/^\[.*\]$/)) {
      return 'validate.reservedName'
    } else if (domain && domain.name && lodash.includes(domain.name, '>')) {
      return 'validate.reservedDomainName'
    }
    return null
  })

  const squareBracketRegexp = /\[\[([^[\]]*?)\]\]/g
  const exampleErrors = lodash.compact(data.examples).map(example => {
    if (example.length > 280) {
      return { id: 'validate.exceededMaxLength', values: { length: 280 } }
    }
    if (lodash.size(example.match(squareBracketRegexp)) > Config.maximumEntitySpecificationNum) {
      return {
        id: 'validate.exceededMaxEntitySpecification',
        values: { length: Config.maximumEntitySpecificationNum },
      }
    }
    return {}
  })
  if (!lodash.every(exampleErrors, lodash.isEmpty)) errors.examples = exampleErrors

  const patternErrors = data.patterns.map(pattern => {
    if (!pattern.pattern) return {}
    if (pattern.pattern.length > 280) {
      return { pattern: 'validate.exceededMaxLength', values: { length: 280 } }
    }
    if (pattern.type === 'regex') {
      try {
        new RegExp(pattern.pattern)
      } catch (e) {
        return { pattern: 'validate.invalidPattern' }
      }
    }
    return {}
  })
  if (!lodash.every(patternErrors, lodash.isEmpty)) {
    errors.patterns = patternErrors
  }

  if (data.actions.length === 0) {
    errors.actions = { _error: 'validate.actionRequired' }
  } else {
    const actionErrors = data.actions.map(validateAction)
    if (!lodash.every(actionErrors, lodash.isEmpty)) errors.actions = actionErrors
  }
  if (lodash.uniq(lodash.compact(data.examples)).length !== lodash.compact(data.examples).length) {
    errors.examples = { _error: 'validate.exampleDuplicated' }
  }

  const patterns = lodash.filter(data.patterns, 'pattern')
  const isEqualPattern = (a, b) => {
    return (a.type || 'partial_match') === (b.type || 'partial_match') && a.pattern === b.pattern
  }
  if (lodash.uniqWith(patterns, isEqualPattern).length !== patterns.length) {
    errors.patterns = { _error: 'validate.patternDuplicated' }
  }

  return errors
}

export class TopicEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    params: PropTypes.shape({
      bot_id: PropTypes.string,
      id: PropTypes.string,
    }),
    applications: PropTypes.array,
    bot: PropTypes.object,
    domain: PropTypes.object,
    examples: PropTypes.array,
    actions: PropTypes.array,
    entities: PropTypes.array,
    integrations: PropTypes.array,
    topic: PropTypes.object,
    topics: PropTypes.array,
    faqs: PropTypes.array,
    faqPdfFiles: PropTypes.array,
    tasks: PropTypes.array,
    currentChannel: PropTypes.string,
    defaultOpenedScenarioMatching: PropTypes.bool,
    tableStates: PropTypes.object,
    enableFeedback: PropTypes.bool,
    root_domain: PropTypes.object,
    domain1: PropTypes.object,
    domain2: PropTypes.object,
    domain3: PropTypes.object,
    domain4: PropTypes.object,
    isDraft: PropTypes.bool,
    // handleSubmit: PropTypes.func
  }

  constructor() {
    super()
    this.state = { isLoadedImage: true, resetting: false, sidebarIndex: 0 }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchBot(state.session.token, this.props.params.bot_id))
    dispatch(fetchDomains(state.session.token, { bot_id: this.props.params.bot_id }))
    dispatch(fetchTopics(state.session.token, { bot_id: this.props.params.bot_id })).then(() => {
      if (this.props.params.id) {
        dispatch(fetchTopic(state.session.token, this.props.params.id))
        dispatch(fetchConditionGroups(state.session.token, { topic_id: this.props.params.id }))
      }
    })
    dispatch(fetchEntities(state.session.token, { bot_id: this.props.params.bot_id }))
    if (isPermitted('feature_integration', this.context)) {
      dispatch(fetchIntegrations(state.session.token, { bot_id: this.props.params.bot_id }))
    }
    if (isPermitted('feature_faq_pdf', this.context)) {
      dispatch(fetchFaqs(state.session.token, { bot_id: this.props.params.bot_id }))
      dispatch(fetchFaqPdfFiles(state.session.token, { bot_id: this.props.params.bot_id }))
    }
    if (isPermitted('feature_task', this.context)) {
      dispatch(fetchTasks(state.session.token, { bot_id: this.props.params.bot_id }))
    }
    dispatch(fetchApplications(state.session.token, { original_bot_id: this.props.params.bot_id }))
    cancelUrl.setRouteLeaveHook(this)
  }

  componentWillUpdate(nextProps) {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    const { actions, isFetching } = nextProps
    const lastactions = this.props.actions

    //  Refresh topics when change topic id
    if (this.props.params.id !== nextProps.params.id && nextProps.params.id) {
      dispatch(fetchTopic(state.session.token, nextProps.params.id))
    }

    //  Remove condition which regard of deleted variable
    if (!actions || isFetching) return
    const variables = collectVariables(this.context, this.props, actions)
    actions.forEach((action, index) => {
      if (!action.condition_variable) {
        if (action.condition_type !== '') this.props.change(`actions[${index}].condition_type`, '')
        if (action.condition_value != null && action.condition_value !== '') {
          this.props.change(`actions[${index}].condition_value`, '')
        }
        return
      }

      const availableVariables = lodash.filter(variables, v => v.index < index)
      const variable = lodash.find(availableVariables, { variable: action.condition_variable }) || {}

      if (!action.condition_value) return
      if (!variable.candidates) return

      if (!lodash.some(variable.candidates, { value: action.condition_value })) {
        this.props.change(`actions[${index}].condition_value`, '')
      }
    })

    //  Remove operator tag value when deleted tag key
    actions.forEach((action, index) => {
      if (action.type !== 'operator') return
      if (!action.tags) return
      if (!lastactions[index]) return

      action.tags.forEach((tag, keyindex) => {
        if (tag && !tag.k && lastactions[index].tags[keyindex].k) {
          this.props.change(`actions[${index}].tags[${keyindex}].v`, '')
        }
      })
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  onChangeSlotDefinition = e => {
    const fieldName = e.target.name.match(/actions\[\d+\]\.slots\[\d+\]/)
    const defaultValue = lodash.at(this.props, `${fieldName}.default`)[0]

    let entityName
    if (e.target.name === `${fieldName}.is_optional`) {
      entityName = lodash.at(this.props, `${fieldName}.entity_name`)[0]
    } else if (e.target.name === `${fieldName}.entity_id`) {
      entityName = getEntityName(this.props.entities, e.target.value)
      const notOptionalType = ['@text', '@number', '@alphanumeric-symbol']
      const canAllowedOptional = !lodash.includes(notOptionalType, entityName)
      if (!canAllowedOptional) {
        this.props.change(`${fieldName}.is_optional`, false)
        this.props.change(`${fieldName}.default`, '')
      }
    }

    this.props.change(`${fieldName}.entity_name`, entityName)

    if (entityName === '@persons' && !defaultValue) {
      this.props.change(`${fieldName}.default`, '[]')
    }
    if (entityName !== '@persons' && defaultValue === '[]') {
      this.props.change(`${fieldName}.default`, '')
    }
  }

  onChangeImageActionType = e => {
    const fieldName = e.target.name.match(/actions\[\d+\]/)[0]
    if (e.target.value === 'none') {
      this.props.change(`${fieldName}.action.value`, '')
    }
  }

  onChangeConditionType = e => {
    const fieldName = e.target.name.match(/actions\[\d+\]/)[0]
    const index = fieldName.match(/.+(\d+).+/)[1]
    const prevValue = this.props.actions[index].condition_type
    const nextValue = e.target.value
    if (prevValue === 'between' || nextValue === 'between') {
      this.props.change(`${fieldName}.condition_value`, '')
    }
  }

  handleDomainValueChanged = (name, newValue, previousValue) => {
    const { dispatch, clearFields, form } = this.props
    if (newValue !== previousValue) {
      if (name === 'domain1') {
        dispatch(clearFields(form, false, false, 'domains[1]', 'domains[2]', 'domains[3]', 'domains[4]'))
      } else if (name === 'domain2') {
        dispatch(clearFields(form, false, false, 'domains[2]', 'domains[3]', 'domains[4]'))
      } else if (name === 'domain3') {
        dispatch(clearFields(form, false, false, 'domains[3]', 'domains[4]'))
      } else if (name === 'domain4') {
        dispatch(clearFields(form, false, false, 'domains[4]'))
      }
    }
  }

  setHierarchicalName = domains => {
    const names = []
    return lodash.map(lodash.filter(domains, 'name'), domain => {
      names.push(domain.name)
      return { ...domain, hierarchicalName: names.join(' > ') }
    })
  }

  handleSave = (data, dispatch, notice = true) => {
    const { bot } = this.props
    const { t } = this.context
    const session = this.context.store.getState().session
    const router = this.context.router

    const formattedActions = data.actions.map((action, index) => {
      const overrideData = { order: index }
      if (action.type === 'generate_answer') {
        overrideData.sources = action.sources.map(source => {
          const [type, value] = source.split(':')
          if (type !== 'tag') {
            return { type, source_id: parseInt(value, 10) }
          } else {
            return { type, tag: value }
          }
        })
      }

      return Object.assign({}, action, overrideData)
    })

    const topic = {
      bot_id: bot.id,
      name: data.name,
      domains: lodash.compact(data.domains.map(domain => !lodash.isEmpty(domain) && domain.name.trim())),
      enable_feedback: data.enable_feedback,
      examples: lodash.compact(data.examples.map(example => example && example.trim())),
      patterns: lodash.compact(
        data.patterns.map(pattern => {
          if (!pattern.pattern || !pattern.pattern.trim()) return null
          return { ...pattern, type: pattern.type || 'partial_match' }
        })
      ),
      actions: sanitizeActions(this.context, this.props, formattedActions),
      is_draft: data.is_draft,
    }

    if (this.props.params.id) {
      topic.id = this.props.params.id
      const currentChannel = this.props.currentChannel
      // Need to self-manage processing flag due to redux form issue.
      // https://github.com/redux-form/redux-form/issues/3866
      this.setState({ resetting: true })
      return dispatch(updateTopic(session.token, topic))
        .then(() => dispatch(resetChannel(currentChannel)))
        .then(() => dispatch(fetchBot(session.token, bot.id)))
        .then(() => dispatch(fetchDomains(session.token, { bot_id: bot.id })))
        .then(() => {
          return notice ? dispatch(addNotice('info', t('common.saveSuccessMessage'))) : false
        })
        .then(() => {
          if (!lodash.every(topic.actions, action => validateActionForLine(action))) {
            dispatch(addNotice('warn', t('topic.exceededBodyOrTextForLine')))
          }
        })
        .then(() => {
          if (notice) {
            checkApplications(bot.id, this.props.applications, dispatch, this.context)
          }
        })
        .then(() => {
          this.props.change('upload_file', '')
        })
        .catch(() => {})
        .then(() => this.setState({ resetting: false, sidebarIndex: 0 }))
    } else {
      return dispatch(createTopic(session.token, topic))
        .then(json =>
          router.push({ pathname: `/bots/${bot.id}/topics/${json.result}`, state: { ignoreBlocking: true } })
        )
        .then(() => dispatch(fetchBot(session.token, bot.id)))
        .then(() => dispatch(fetchDomains(session.token, { bot_id: bot.id })))
        .then(() => cancelUrl.setRouteLeaveHook(this))
        .then(() => {
          return notice ? dispatch(addNotice('info', t('common.saveSuccessMessage'))) : false
        })
        .then(() => {
          if (!lodash.every(topic.actions, action => validateActionForLine(action))) {
            dispatch(addNotice('warn', t('topic.exceededBodyOrTextForLine')))
          }
        })
        .then(() => {
          if (notice) {
            checkApplications(bot.id, this.props.applications, dispatch, this.context)
          }
        })
        .then(() => this.setState({ sidebarIndex: 0 }))
    }
  }

  handleDelete = () => {
    const { t } = this.context
    const { topic } = this.props
    if (!window.confirm(t('common.deleteConfirmMessage', { type: t('topic.title'), name: topic.name })))
      return
    const state = this.context.store.getState()
    const router = this.context.router
    const { dispatch, bot } = this.props
    const id = parseInt(this.props.params.id, 10)
    return dispatch(resetChannel(this.props.currentChannel))
      .then(() => dispatch(deleteTopic(state.session.token, id)))
      .then(() => dispatch(fetchDomains(state.session.token, { bot_id: bot.id })))
      .then(() => router.push({ pathname: `/bots/${bot.id}`, state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .then(() => checkApplications(bot.id, this.props.applications, dispatch, this.context))
  }

  onChangeImageMethod = e => {
    const action_path = e.target.name.match(/(actions\[\d+\])/)[1]
    this.props.change(`${action_path}.use_action`, false)
  }

  // check of file type and file size
  // maximum file size is 1MB(=1048576byte),
  // and height and width are 4096 * 4096.
  onChangeImageFile = (e, callback) => {
    const { dispatch } = this.props

    const action_path = e.target.name.match(/(actions\[\d+\])/)[1]
    const callbackForUpload = uploadedImage => {
      if (uploadedImage) {
        callback(uploadedImage.image_url)
        this.props.change(`${action_path}.upload_image_url`, uploadedImage.image_url)
        this.props.change(`${action_path}.upload_thumb_url`, uploadedImage.thumb_url)
        this.props.change(`${action_path}.isInvalidImage`, false)
        this.props.change(`${action_path}.width`, uploadedImage.width)
        this.props.change(`${action_path}.height`, uploadedImage.height)
      } else {
        callback(null)
        this.props.change(`${action_path}.upload_image_url`, null)
        this.props.change(`${action_path}.isInvalidImage`, true)
      }

      this.setState({ isLoadedImage: true })
    }

    this.setState({ isLoadedImage: false })
    const state = this.context.store.getState()
    const image = e.target.files[0]
    const restrictions = {
      maxHeight: 4096,
      maxWidth: 4096,
      maxFileSize: 1048576,
      allowedFileFormats: ['image/jpeg'],
    }
    uploadImageFileWithValidation(
      dispatch,
      state.session.token,
      'upload_image',
      image,
      callbackForUpload,
      restrictions
    )
  }

  // check of file type and file size
  // maximum file size is 1MB(=1048576byte).
  onChangeChooseScenarioImageFile = (e, callback) => {
    const { dispatch } = this.props

    const action_path = e.target.name.match(/(actions\[\d+\])/)[1]
    const callbackForUpload = uploadedImage => {
      const imageUrl = uploadedImage ? uploadedImage.image_url : null
      this.props.change(`${action_path}.upload_image_url`, imageUrl)
      callback(imageUrl)
    }

    const state = this.context.store.getState()
    const image = e.target.files[0]
    const restrictions = {
      maxHeight: 1024,
      maxWidth: 1024,
      maxFileSize: 1048576,
      allowedFileFormats: ['image/jpeg', 'image/png'],
    }
    uploadImageFileWithValidation(
      dispatch,
      state.session.token,
      'choose_scenario',
      image,
      callbackForUpload,
      restrictions
    )
  }

  // check of file type and file size
  // maximum file size is 1MB(=1048576byte).
  onChangeCarouselImageFile = (e, callback) => {
    const { dispatch } = this.props

    const item_path = e.target.name.match(/(actions\[\d+\]\.items\[\d+\])/)[1]
    const callbackForUpload = uploadedImage => {
      const imageUrl = uploadedImage ? uploadedImage.image_url : null
      this.props.change(`${item_path}.upload_image_url`, imageUrl)
      callback(imageUrl)
    }

    const state = this.context.store.getState()
    const image = e.target.files[0]
    const restrictions = {
      maxHeight: 1024,
      maxWidth: 1024,
      maxFileSize: 1048576,
      allowedFileFormats: ['image/jpeg', 'image/png'],
    }
    uploadImageFileWithValidation(
      dispatch,
      state.session.token,
      'carousel',
      image,
      callbackForUpload,
      restrictions
    )
  }

  onClickImageReset = item_path => {
    this.props.change(`${item_path}.upload_image_url`, null)
  }

  onChangeSidebar = index => {
    this.setState({ sidebarIndex: index })
  }

  onRefreshConditionGroup = () => {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.props.params.id) {
      dispatch(fetchConditionGroups(state.session.token, { topic_id: this.props.params.id }))
    }
  }

  onError = (message, options = {}) => {
    const level = options.level || 'error'
    this.props.dispatch(addNotice(level, message))
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const { isLoadedImage, resetting, sidebarIndex } = this.state
    const {
      isFetching,
      defaultOpenedScenarioMatching,
      submitting,
      handleSubmit,
      bot,
      domains,
      domain,
      topic,
      root_domain,
      domain1,
      domain2,
      domain3,
      domain4,
      currentDomains,
      entities,
      actions,
      integrations,
      topics,
      faqs,
      faqPdfFiles,
      condition_groups,
      tasks,
      tableStates,
      enableFeedback,
      isDraft,
    } = this.props
    const variables = collectVariables(this.context, this.props, actions)
    const isHidden = resetting || isFetching ? true : false

    const titles = []
    titles.push(t('simulator.title'))
    titles.push(t('condition_group.scenarioOption'))

    return (
      <div>
        <Loader loaded={!isFetching && !submitting && !resetting} type={isHidden ? 'hidden' : 'show'}>
          <TopicEditComponent
            bot={bot}
            domains={domains}
            root_domain={root_domain}
            domain1={domain1}
            domain2={domain2}
            domain3={domain3}
            domain4={domain4}
            currentDomains={currentDomains}
            topic={topic}
            entities={entities}
            actions={actions}
            integrations={integrations}
            topics={topics}
            faqs={faqs}
            faqPdfFiles={faqPdfFiles}
            tasks={tasks}
            onChangeImageMethod={this.onChangeImageMethod}
            onChangeImageFile={this.onChangeImageFile}
            onClickImageReset={this.onClickImageReset}
            onChangeChooseScenarioImageFile={this.onChangeChooseScenarioImageFile}
            onChangeCarouselImageFile={this.onChangeCarouselImageFile}
            onError={this.onError}
            isLoadedImage={isLoadedImage}
            variables={variables}
            defaultOpenedScenarioMatching={defaultOpenedScenarioMatching}
            isSubmitting={submitting}
            handleDomainValueChanged={this.handleDomainValueChanged}
            handleSubmit={handleSubmit}
            handleSave={handleSubmit(this.handleSave)}
            handleDelete={this.handleDelete}
            onChangeSlotDefinition={this.onChangeSlotDefinition}
            onChangeImageActionType={this.onChangeImageActionType}
            onChangeConditionType={this.onChangeConditionType}
            condition_groups={condition_groups}
            enableFeedback={enableFeedback}
            isDraft={isDraft}
          />
        </Loader>
        <Sidebar titles={titles} onChangeSidebar={this.onChangeSidebar}>
          {bot.id && sidebarIndex === 0 && (
            <Simulator
              tabs={['topicClassifier', 'chat']}
              bot={bot}
              domain={domain}
              currentPageItemId={this.props.params.id}
              isCurrentPageFormDirty={isDirtyForm(this)}
            />
          )}
          {sidebarIndex === 1 && (
            <ScenarioOption
              bot={bot}
              variables={variables}
              topic_id={this.props.params.id}
              condition_groups={condition_groups}
              onRefresh={this.onRefreshConditionGroup}
              tableState={tableStates['scenarioOption']}
              updateTableState={this.updateTableState}
            />
          )}
        </Sidebar>
      </div>
    )
  }
}

const TopicEditForm = reduxForm({
  form: 'TopicEdit',
  enableReinitialize: true,
  validate,
  // Execute "validate" every time rendering.
  shouldError: () => true,
})(TopicEdit)

const selector = formValueSelector('TopicEdit')

export const mapStateToProps = (state, props) => {
  const applications = lodash.filter(state.entities.applications)
  const bot = state.entities.bots[props.params.bot_id] || {}
  const domains = lodash.filter(state.entities.domains, { bot_id: bot.id })
  const domainIds = domains.map(domain => domain.id)
  const topic = state.entities.topics[props.params.id] || {}
  const topics = lodash.filter(
    state.entities.topics,
    topic => topic.type === 'normal' && !topic.is_draft && lodash.includes(domainIds, topic.domain_id)
  )
  const topicsWithoutSelf = lodash.reject(topics, { id: topic.id })
  const root_domain = lodash.find(state.entities.domains, { bot_id: bot.id, parent_id: null })
  const defaultValues = {
    enable_feedback: true,
    domains: [],
    examples: [],
    patterns: [],
    actions: [],
    is_draft: false,
  }

  let domain1 = undefined
  if (root_domain) {
    domain1 = lodash.find(root_domain.domains, { name: selector(state, 'domains[0].name') })
    domain1 = domain1 ? lodash.find(state.entities.domains, { id: domain1.id }) : undefined
  }
  let domain2 = undefined
  if (domain1) {
    domain2 = lodash.find(domain1.domains, { name: selector(state, 'domains[1].name') })
    domain2 = domain2 ? lodash.find(state.entities.domains, { id: domain2.id }) : undefined
  }
  let domain3 = undefined
  if (domain2) {
    domain3 = lodash.find(domain2.domains, { name: selector(state, 'domains[2].name') })
    domain3 = domain3 ? lodash.find(state.entities.domains, { id: domain3.id }) : undefined
  }
  let domain4 = undefined
  if (domain3) {
    domain4 = lodash.find(domain3.domains, { name: selector(state, 'domains[3].name') })
    domain4 = domain4 ? lodash.find(state.entities.domains, { id: domain4.id }) : undefined
  }

  let condition_groups = []
  if (props.params.id) {
    condition_groups = lodash.filter(state.entities.condition_groups, {
      topic_id: parseInt(props.params.id, 10),
    })
  }

  const entities = lodash.orderBy(state.entities.entities, ['is_system', 'id'], ['desc', 'asc'])
  // whether example field is opened or not. This is for the "add to example as new scenario" feature in the PFL table.
  let defaultOpenedScenarioMatching = true
  if (!props.params.id) {
    if (props.location.query.example) {
      defaultValues.examples = [props.location.query.example]
    }
  } else if (topic.examples && topic.examples.length > 0) {
    defaultOpenedScenarioMatching = false
  }

  if (topic.actions) {
    topic.actions.forEach(action => {
      convertSystemVariablesOfAction(action, false)
    })
  }

  //  Add trailing undefined to render empty row in tables
  const initialValues = {
    ...defaultValues,
    ...topic,
  }
  initialValues.examples = [...initialValues.examples, undefined]
  initialValues.actions = initialValues.actions.map(action => {
    switch (action.type) {
      case 'slot': {
        const slotsWithEntityName = lodash.map(action.slots, slot => {
          return { ...slot, entity_name: getEntityName(entities, slot.entity_id) }
        })
        return {
          ...action,
          slots: [...slotsWithEntityName, undefined],
        }
      }
      case 'operator':
        return {
          ...action,
          tags: [...action.tags],
        }
      case 'upload_image':
        return {
          ...action,
          upload_image_url: action.image_method === 'upload' ? action.image_url : undefined,
          upload_thumb_url: action.image_method === 'upload' ? action.thumb_url : undefined,
          input_url: action.image_method === 'url' ? action.image_url : undefined,
        }
      case 'carousel':
        return {
          ...action,
          items: action.items.map(item => {
            return {
              ...item,
              upload_image_url: item.image_method === 'upload' ? item.image_url : undefined,
              input_image_url: item.image_method === 'url' ? item.image_url : undefined,
            }
          }),
        }
      case 'choose_scenario':
        return {
          ...action,
          upload_image_url: action.image_method === 'upload' ? action.image_url : undefined,
          input_image_url: action.image_method === 'url' ? action.image_url : undefined,
          choices: action.choices.map(choice => {
            return choice.type === 'domain'
              ? { type: 'domain', domain_id: choice.domain_id }
              : { type: 'topic', topic_id: choice.topic_id }
          }),
        }
      case 'form': {
        const fields = action.fields.map(field => {
          return {
            ...field,
            arguments: {
              default_value_type: 'constant',
              data_type: 'constant',
              ...field.arguments,
            },
          }
        })

        return {
          ...action,
          fields: [...fields, { arguments: { data_type: 'constant', default_value_type: 'constant' } }],
        }
      }
      case 'generate_answer': {
        return {
          prompt: i18next.t('topic.actionList.generate_answer.customizedPrompt.default'),
          ...action,
          sources: action.sources.map(source => `${source.type}:${source.source_id || source.tag}`),
        }
      }
      default:
        return action
    }
  })

  const tableStates = {
    scenarioOption: state.table[`${window.location.pathname}#TopicConditionGroupList`],
  }

  return {
    isFetching: isFetching(state, ['bots', 'domains', 'topics', 'entities', 'integrations', 'applications']),
    initialValues: initialValues,
    applications,
    bot,
    domain: root_domain,
    root_domain: root_domain,
    domain1: domain1,
    domain2: domain2,
    domain3: domain3,
    domain4: domain4,
    topic,
    condition_groups: condition_groups,
    currentDomains: selector(state, 'domains'),
    examples: selector(state, 'examples'),
    actions: selector(state, 'actions'),
    entities: entities,
    integrations: lodash.filter(state.entities.integrations),
    domains,
    topics: topicsWithoutSelf,
    faqs: lodash.filter(state.entities.faqs, { bot_id: bot.id }),
    faqPdfFiles: lodash.filter(state.entities.faq_pdf_files, { bot_id: bot.id }),
    tasks: lodash.filter(state.entities.tasks, { bot_id: bot.id }),
    currentChannel: state.chat.currentChannel,
    defaultOpenedScenarioMatching,
    tableStates: tableStates,
    enableFeedback: bot.use_feedback && selector(state, 'enable_feedback'),
    isDraft: selector(state, 'is_draft'),
  }
}

export default connect(mapStateToProps)(TopicEditForm)
