import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes } from 'redux-form'
import { UAParser } from 'ua-parser-js'

// components
import Loader from '../../components/common/Loader'
import BotEditComponent from '../../components/bot/BotEdit'
import Simulator from '../../containers/simulator/Simulator'
import Sidebar from '../../components/common/Sidebar'
import CopyDialog from '../../components/bot/CopyDialog'
import DeleteConfirm from '../../components/common/DeleteConfirm'

// actions
import { fetchAccount } from '../../actions/account'
import { updateCurrentChannel } from '../../actions/embedded'
import { addNotice } from '../../actions/notice'
import { fetchBots, fetchBot, updateBot, createBot, copyBot, deleteBot, exportBot } from '../../actions/bot'
import { fetchDomains } from '../../actions/domain'
import { fetchTopics } from '../../actions/topic'
import { fetchFaqs } from '../../actions/faq'
import { fetchFaqPdfFiles } from '../../actions/faq_pdf_file'
import { fetchIntegrations } from '../../actions/integration'
import { fetchTasks } from '../../actions/task'
import { fetchEntities } from '../../actions/entity'
import { fetchApplications } from '../../actions/application'
import { fetchOAuthClients } from '../../actions/oauth_client'
import { resetChannel } from '../../actions/embedded'
import { updateTableState } from '../../actions/table'
import { updateTopicsStyle } from '../../actions/session'

// helpers
import Config from '../../helpers/config'
import { isFetching } from '../../helpers/selector'
import { isPermitted } from '../../helpers/permission'
import { checkApplications } from '../../helpers/checkApplications'
import cancelUrl, { isDirtyForm } from '../../helpers/cancelurl'
import { uploadImageFileWithValidation } from '../../helpers/image'
import { isIncludingInvalidSymbols, lengthWithoutVariable } from '../../helpers/validation'
import { replaceSystemVariableWhenOnChange } from '../../helpers/replaceSystemVariable'

const validate = (data, props) => {
  const { domains, topics } = props
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.length > 100) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 100 } }
  }

  errors.menu = {}
  if (data.default_behavior === 'menu') {
    if (lodash.filter(data.menu.choices).length === 0) {
      errors.menu.choices = { _error: 'validate.required' }
    } else {
      errors.menu.choices = lodash.map(lodash.compact(data.menu.choices), choice => {
        if (choice.type === 'topic' && !lodash.some(topics, { id: choice.topic_id })) {
          return 'validate.required'
        }
        if (choice.type === 'domain' && !lodash.some(domains, { id: choice.domain_id })) {
          return 'validate.required'
        }
      })
    }
  }
  if (data.default_behavior === 'default_topic') {
    if (!data.default_topic_id) {
      errors.default_topic_id = 'validate.required'
    } else if (!lodash.some(topics, { id: data.default_topic_id })) {
      errors.default_topic_id = 'validate.required'
    }
  }
  if (data.use_classification_fails_topic_for_topic) {
    if (!data.classification_fails_count_for_topic) {
      errors.option_of_classification_fails_topic_for_topic = 'validate.required'
    } else if (data.classification_fails_count_for_topic < 1) {
      errors.option_of_classification_fails_topic_for_topic = {
        id: 'validate.minimumLimit',
        values: { minimum: 1 },
      }
    } else if (data.classification_fails_count_for_topic > 3) {
      errors.option_of_classification_fails_topic_for_topic = {
        id: 'validate.maximumLimit',
        values: { maximum: 3 },
      }
    } else if (!data.classification_fails_topic_for_topic_id) {
      errors.option_of_classification_fails_topic_for_topic = 'validate.selectRequired'
    } else if (!lodash.some(topics, { id: data.classification_fails_topic_for_topic_id })) {
      errors.option_of_classification_fails_topic_for_topic = 'validate.selectRequired'
    }
  }
  if (data.use_classification_fails_topic_for_faq) {
    if (!data.classification_fails_count_for_faq) {
      errors.option_of_classification_fails_topic_for_faq = 'validate.required'
    } else if (data.classification_fails_count_for_faq < 1) {
      errors.option_of_classification_fails_topic_for_faq = {
        id: 'validate.minimumLimit',
        values: { minimum: 1 },
      }
    } else if (data.classification_fails_count_for_faq > 3) {
      errors.option_of_classification_fails_topic_for_faq = {
        id: 'validate.maximumLimit',
        values: { maximum: 3 },
      }
    } else if (!data.classification_fails_topic_for_faq_id) {
      errors.option_of_classification_fails_topic_for_faq = 'validate.selectRequired'
    } else if (!lodash.some(topics, { id: data.classification_fails_topic_for_faq_id })) {
      errors.option_of_classification_fails_topic_for_faq = 'validate.selectRequired'
    }
  }
  if (data.use_first_topic) {
    if (!data.first_topic_id) {
      errors.first_topic_id = 'validate.required'
    } else if (!lodash.some(topics, { id: data.first_topic_id })) {
      errors.first_topic_id = 'validate.required'
    }
  }
  if (data.use_office365) {
    if (!data.oauth_client_id_office365) {
      errors.oauth_client_id_office365 = 'validate.required'
    }
  }
  if (data.use_salesforce) {
    if (!data.oauth_client_id_salesforce) {
      errors.oauth_client_id_salesforce = 'validate.required'
    }
  }
  if (data.use_gpt) {
    if (data.openai_type === 'azure') {
      if (!data.openai_endpoint) {
        errors.openai_endpoint = 'validate.required'
      }
      if (!data.openai_embedding_deployment) {
        errors.openai_embedding_deployment = 'validate.required'
      }
      if (!data.openai_completion_deployment) {
        errors.openai_completion_deployment = 'validate.required'
      }
      if (props.bot.openai_type !== data.openai_type && !data.openai_api_key) {
        errors.openai_api_key = 'validate.required'
      }
    } else if (data.openai_type === 'openai') {
      if (!data.openai_embedding_model) {
        errors.openai_embedding_model = 'validate.required'
      }
      if (!data.openai_completion_model) {
        errors.openai_completion_model = 'validate.required'
      }
      if (props.bot.openai_type !== data.openai_type && !data.openai_api_key) {
        errors.openai_api_key = 'validate.required'
      }
    } else if (data.openai_type === 'bedrock') {
      if (!data.aws_region) {
        errors.aws_region = 'validate.required'
      }
      if (!data.aws_completion_model) {
        errors.aws_completion_model = 'validate.required'
      }
      if (props.bot.openai_type !== data.openai_type && !data.aws_access_key_id) {
        errors.aws_access_key_id = 'validate.required'
      }
      if (props.bot.openai_type !== data.openai_type && !data.aws_secret_access_key) {
        errors.aws_secret_access_key = 'validate.required'
      }
    }
  }
  if (data.use_custom_operator) {
    if (data.calling_operator_button && data.calling_operator_button.length > 20) {
      errors.calling_operator_button = { id: 'validate.exceededMaxLength', values: { length: 20 } }
    }
    if (data.calling_operator_message && data.calling_operator_message.length > 280) {
      errors.calling_operator_message = { id: 'validate.exceededMaxLength', values: { length: 280 } }
    }
    if (data.operator_typing_message && data.operator_typing_message.length > 280) {
      errors.operator_typing_message = { id: 'validate.exceededMaxLength', values: { length: 280 } }
    }
    if (data.enable_operator_business_hours) {
      const error = {}

      if (!lodash.some(data.operator_business_hours.day_of_week)) {
        error.day_of_week = { id: 'validate.dayOfWeekRequired' }
      }

      if (data.operator_business_hours.is_full_time === 'false') {
        lodash.forEach(['from', 'to'], key => {
          const targetData = lodash.get(data, `operator_business_hours.${key}`)
          if (!targetData) {
            error[key] = { id: 'validate.required' }
          } else {
            const matched = targetData.match(/^(\d{2}):(\d{2})$/)
            if (!matched) {
              error[key] = { id: 'validate.invalid' }
            } else {
              const hours = parseInt(matched[1], 10)
              const minutes = parseInt(matched[2], 10)
              if (hours >= 24 || minutes >= 60) {
                error[key] = { id: 'validate.invalidTime' }
              }
            }
          }
        })
      }

      if (!lodash.isEmpty(error)) {
        errors.operator_business_hours = error
      }
    }
  }
  if (data.enable_digit_masking) {
    if (!data.digit_masking_length) {
      errors.digit_masking_length = 'validate.required'
    } else {
      if (!data.digit_masking_length.toString().match(/^\d+$/)) {
        errors.digit_masking_length = 'validate.invalid'
      } else {
        const digit_masking_length = parseInt(data.digit_masking_length, 10)
        if (digit_masking_length < 4) {
          errors.digit_masking_length = {
            id: 'validate.minimumLimit',
            values: { minimum: '4' },
          }
        } else if (20 < digit_masking_length) {
          errors.digit_masking_length = {
            id: 'validate.maximumLimit',
            values: { maximum: '20' },
          }
        }
      }
    }
  }
  if (data.use_reset) {
    if (data.show_reset_in_balloon) {
      if (data.reset_label_in_balloon && data.reset_label_in_balloon.length > 16) {
        errors.reset_label_in_balloon = { id: 'validate.exceededMaxLength', values: { length: 16 } }
      }
    }
  }
  if (data.use_undo) {
    if (data.show_undo_in_balloon) {
      if (data.undo_label_in_balloon && data.undo_label_in_balloon.length > 16) {
        errors.undo_label_in_balloon = { id: 'validate.exceededMaxLength', values: { length: 16 } }
      }
    }
  }

  var names = []
  errors.memory_definitions =
    data.memory_definitions &&
    data.memory_definitions.map(memory_definition => {
      if (!memory_definition.name) {
        return { name: 'validate.required' }
      }
      if (names && names.indexOf(memory_definition.name) >= 0) {
        return { name: 'validate.nameDuplicated' }
      }
      if (lodash.some(data.constant_definitions || [], { name: memory_definition.name })) {
        return { name: 'validate.nameDuplicatedConstantDefinition' }
      }
      names.push(memory_definition.name)
      return {}
    })

  if (data.constant_definitions) {
    const constant_definition_names = []
    const constantDefinitionErrors = data.constant_definitions.map(constant_definition => {
      const error = {}
      if (!constant_definition.name) {
        error.name = 'validate.required'
      } else {
        if (constant_definition.name.length > 30) {
          error.name = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        } else if (isIncludingInvalidSymbols(constant_definition.name)) {
          error.name = 'validate.invalidSymbolIncluded'
        } else if (lodash.includes(constant_definition_names, constant_definition.name)) {
          error.name = 'validate.nameDuplicated'
        } else if (lodash.some(data.memory_definitions || [], { name: constant_definition.name })) {
          error.name = 'validate.nameDuplicatedMemoryDefinition'
        } else {
          constant_definition_names.push(constant_definition.name)
        }
      }

      if (!constant_definition.value) {
        if (!constant_definition.is_secret) {
          error.value = 'validate.required'
        }
      } else {
        if (constant_definition.value.length > 1000) {
          error.value = { id: 'validate.exceededMaxLength', values: { length: 1000 } }
        }
      }

      return error
    })
    if (!lodash.every(constantDefinitionErrors, lodash.isEmpty)) {
      errors.constant_definitions = constantDefinitionErrors
    }
  }

  if (data.use_reset) {
    const resetPatterns = []
    const resetPatternErrors = lodash.map(data.reset_patterns || [], resetPattern => {
      const error = {}
      if (resetPattern.pattern && !resetPattern.is_preset) {
        if (lodash.includes(resetPatterns, resetPattern.pattern)) {
          error.pattern = 'validate.triggerUtteranceDuplicated'
        } else if (lodash.some(data.undo_patterns || [], { pattern: resetPattern.pattern })) {
          error.pattern = 'validate.triggerUtteranceDuplicatedUndo'
        } else {
          resetPatterns.push(resetPattern.pattern)
        }
      }
      return error
    })
    if (!lodash.every(resetPatternErrors, lodash.isEmpty)) errors.reset_patterns = resetPatternErrors
  }

  if (data.use_undo) {
    const undoPatterns = []
    const undoPatternErrors = lodash.map(data.undo_patterns || [], undoPattern => {
      const error = {}
      if (undoPattern.pattern && !undoPattern.is_preset) {
        if (lodash.includes(undoPatterns, undoPattern.pattern)) {
          error.pattern = 'validate.triggerUtteranceDuplicated'
        } else if (lodash.some(data.reset_patterns || [], { pattern: undoPattern.pattern })) {
          error.pattern = 'validate.triggerUtteranceDuplicatedReset'
        } else {
          undoPatterns.push(undoPattern.pattern)
        }
      }
      return error
    })
    if (!lodash.every(undoPatternErrors, lodash.isEmpty)) errors.undo_patterns = undoPatternErrors
  }

  return errors
}

export const convertSystemVariablesOfBot = (bot, isSave) => {
  replaceSystemVariableWhenOnChange(bot, 'first_message', isSave, true)
  replaceSystemVariableWhenOnChange(bot, 'feedback_question', isSave, true)
  replaceSystemVariableWhenOnChange(bot, 'feedback_reply_yes', isSave, true)
  replaceSystemVariableWhenOnChange(bot, 'feedback_reply_no', isSave, true)
  replaceSystemVariableWhenOnChange(bot, 'template_breakdown_classification_failure', isSave, true)
  replaceSystemVariableWhenOnChange(bot, 'operator_calling', isSave, true)
  replaceSystemVariableWhenOnChange(bot, 'operator_pickup', isSave, true)
  replaceSystemVariableWhenOnChange(bot, 'operator_hangup', isSave, true)
  if (bot.menu) {
    replaceSystemVariableWhenOnChange(bot.menu, 'text', isSave, true)
  }
}

export class BotEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }),
    botEditForm: PropTypes.object,
    isFetching: PropTypes.bool,
    applications: PropTypes.array,
    bot: PropTypes.object,
    bots: PropTypes.array,
    domains: PropTypes.array,
    topics: PropTypes.array,
    availableTopics: PropTypes.array,
    faqs: PropTypes.array,
    faqPdfFiles: PropTypes.array,
    integrations: PropTypes.array,
    tasks: PropTypes.array,
    entities: PropTypes.array,
    currentChannel: PropTypes.string,
    office365OAuthClients: PropTypes.array,
    salesforceOAuthClients: PropTypes.array,
    tableStates: PropTypes.object,
    topicsStyle: PropTypes.string,
    useExportImport: PropTypes.bool,
    maxScenarios: PropTypes.number.isRequired,
  }

  constructor() {
    super()
    this.state = {
      isCopyDialogOpened: false,
      isDeleteConfirmOpened: false,
      resetting: false,
      deleteConfirmErrorMessage: '',
    }
  }

  componentDidMount() {
    this.refreshBot()
    cancelUrl.setRouteLeaveHook(this)
  }

  componentDidUpdate(prevProps) {
    const { t } = this.context
    const {
      botEditForm: { language },
    } = this.props
    //  When turn on reset feature
    if (this.props.botEditForm.use_reset && !prevProps.botEditForm.use_reset) {
      if (lodash.isEmpty(this.props.botEditForm.reset_patterns)) {
        this.props.array.push('reset_patterns', {
          is_preset: true,
          pattern: t('triggers.reset', { lng: language }),
        })
      }
    }

    //  When turn on undo feature
    if (this.props.botEditForm.use_undo && !prevProps.botEditForm.use_undo) {
      if (lodash.isEmpty(this.props.botEditForm.undo_patterns)) {
        this.props.array.push('undo_patterns', {
          is_preset: true,
          pattern: t('triggers.undo', { lng: language }),
        })
      }
    }

    //  Change language
    if (this.props.botEditForm.language !== prevProps.botEditForm.language) {
      if (this.props.botEditForm.use_reset) {
        this.props.array.shift('reset_patterns')
        this.props.array.unshift('reset_patterns', {
          is_preset: true,
          pattern: t('triggers.reset', { lng: language }),
        })
      }

      if (this.props.botEditForm.use_undo) {
        this.props.array.shift('undo_patterns')
        this.props.array.unshift('undo_patterns', {
          is_preset: true,
          pattern: t('triggers.undo', { lng: language }),
        })
      }
    }

    //  Default openai type
    if (!this.props.botEditForm.openai_type) {
      if (isPermitted('feature_openai_default', this.context)) {
        this.props.change('openai_type', 'default')
      } else {
        this.props.change('openai_type', 'azure')
      }
    }

    if (this.props.params.id === prevProps.params.id) return
    this.refreshBot()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  refreshBot = () => {
    if (!this.props.params.id) return

    const bot_id = parseInt(this.props.params.id, 10)
    const state = this.context.store.getState()
    const { t } = this.context
    const { dispatch } = this.props

    dispatch(fetchBot(state.session.token, bot_id)).then(response => {
      const bot = response.entities.bots[response.result]
      if (isPermitted('feature_faq_pdf', this.context) && !bot.has_tagged_faq_pdf_files) {
        dispatch(fetchFaqs(state.session.token, { bot_id }))
        dispatch(fetchFaqPdfFiles(state.session.token, { bot_id }))
      }
    })
    dispatch(fetchDomains(state.session.token, { bot_id }))
    dispatch(fetchTopics(state.session.token, { bot_id })).then(result => {
      if (lodash.some(result.entities.topics, { is_draft: false, action_count: 0 })) {
        dispatch(addNotice('warn', t('bot.noActionScenario'), { removable: false }))
      }
    })
    if (isPermitted('feature_integration', this.context)) {
      dispatch(fetchIntegrations(state.session.token, { bot_id: this.props.params.id }))
    }
    if (isPermitted('feature_task', this.context)) {
      dispatch(fetchTasks(state.session.token, { bot_id: this.props.params.id }))
    }
    if (isPermitted('feature_oauth', this.context)) {
      dispatch(fetchOAuthClients(state.session.token))
    }

    dispatch(fetchEntities(state.session.token, { bot_id: this.props.params.id }))
    dispatch(fetchApplications(state.session.token, { original_bot_id: this.props.params.id }))
    dispatch(fetchAccount(state.session.token))
  }

  /**
   * Save (update and create) a bot
   *
   */
  handleSave = (data, dispatch) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const router = this.context.router
    const { office365OAuthClients, salesforceOAuthClients, integrations } = this.props

    // Enable preauthentication for Office365
    if (this.props.bot.oauth_client_id_office365 !== data.oauth_client_id_office365) {
      const thisClient =
        lodash.find(office365OAuthClients, { id: this.props.bot.oauth_client_id_office365 }) || {}
      const nextClient = lodash.find(office365OAuthClients, { id: data.oauth_client_id_office365 }) || {}

      if (!thisClient.use_preauthentication && nextClient.use_preauthentication) {
        // Integrations that can not use with preauthentication
        const filters = [
          { type: 'office365', api_subtype: 'get_user', target_type: 'me' },
          { type: 'office365', api_subtype: 'get_users' },
          { type: 'office365', api_subtype: 'get_groups', target_type: 'me' },
          { type: 'office365', api_subtype: 'get_event', target_type: 'me' },
          { type: 'office365', api_subtype: 'get_events', target_type: 'me' },
          { type: 'office365', api_subtype: 'search_free_room' },
          { type: 'office365', api_subtype: 'post_event' },
          { type: 'office365', api_subtype: 'update_event' },
          { type: 'office365', api_subtype: 'delete_event' },
          { type: 'office365', api_subtype: 'logout' },
        ]

        const filteredIntegrations = lodash.flatten(
          lodash.map(filters, filter => lodash.filter(integrations, filter))
        )

        if (!lodash.isEmpty(filteredIntegrations)) {
          const messages = lodash.concat(
            t('bot.useOffice365Preauthentication'),
            lodash.map(filteredIntegrations, integration => `- ${integration.name}`)
          )
          if (!window.confirm(messages.join('\n'))) return
        }
      }
    }

    // Enable preauthentication for Salesforce
    if (this.props.bot.oauth_client_id_salesforce !== data.oauth_client_id_salesforce) {
      const thisClient =
        lodash.find(office365OAuthClients, { id: this.props.bot.oauth_client_id_salesforce }) || {}
      const nextClient = lodash.find(salesforceOAuthClients, { id: data.oauth_client_id_salesforce }) || {}

      if (!thisClient.use_preauthentication && nextClient.use_preauthentication) {
        // Integrations that can not use with preauthentication
        const filters = [{ type: 'salesforce', api_subtype: 'logout' }]

        const filteredIntegrations = lodash.flatten(
          lodash.map(filters, filter => lodash.filter(integrations, filter))
        )

        if (!lodash.isEmpty(filteredIntegrations)) {
          const messages = lodash.concat(
            t('bot.useSalesforcePreauthentication'),
            lodash.map(filteredIntegrations, integration => `- ${integration.name}`)
          )
          if (!window.confirm(messages.join('\n'))) return
        }
      }
    }

    let bot = {
      name: data.name,
      first_message: data.first_message ? data.first_message : null,
    }

    if (this.props.params.id) {
      bot.id = this.props.params.id
      bot.use_first_topic = !!data.use_first_topic
      bot.first_topic_id = data.first_topic_id ? data.first_topic_id : null
      bot.use_custom_messages = !!data.use_custom_messages
      bot.template_breakdown_classification_failure = data.template_breakdown_classification_failure || null
      bot.operator_calling = data.operator_calling || null
      bot.operator_pickup = data.operator_pickup || null
      bot.operator_hangup = data.operator_hangup || null
      bot.operator_non_business_hours = data.operator_non_business_hours || null
      bot.application_republish = data.application_republish || null

      bot.use_custom_operator = !!data.use_custom_operator
      bot.calling_operator_button = data.calling_operator_button || null
      bot.calling_operator_message = data.calling_operator_message || null
      bot.operator_typing_message = data.operator_typing_message || null
      bot.is_display_operator_icon = !!data.is_display_operator_icon

      bot.enable_operator_business_hours = !!data.enable_operator_business_hours
      if (bot.enable_operator_business_hours) {
        const is_full_time = data.operator_business_hours.is_full_time === 'true'
        bot.operator_business_hours = {
          day_of_week: data.operator_business_hours.day_of_week,
          is_full_time: is_full_time,
        }
        if (!is_full_time) {
          bot.operator_business_hours.from = data.operator_business_hours.from
          bot.operator_business_hours.to = data.operator_business_hours.to
        }
      } else {
        bot.operator_business_hours = null
      }

      bot.use_classification_fails_topic_for_topic = !!data.use_classification_fails_topic_for_topic
      bot.classification_fails_count_for_topic = data.classification_fails_count_for_topic
        ? data.classification_fails_count_for_topic
        : null
      bot.classification_fails_topic_for_topic_id = data.classification_fails_topic_for_topic_id
        ? data.classification_fails_topic_for_topic_id
        : null

      bot.use_classification_fails_topic_for_faq = !!data.use_classification_fails_topic_for_faq
      bot.classification_fails_count_for_faq = data.classification_fails_count_for_faq
        ? data.classification_fails_count_for_faq
        : null
      bot.classification_fails_topic_for_faq_id = data.classification_fails_topic_for_faq_id
        ? data.classification_fails_topic_for_faq_id
        : null

      bot.skip_echo_for_classification = data.skip_echo_for_classification

      bot.use_menu = data.default_behavior === 'menu' ? true : false
      bot.menu = { ...data.menu }
      bot.menu.choices = lodash.map(lodash.filter(data.menu.choices), (choice, index) => {
        return { ...choice, order: index }
      })
      bot.menu.image_url =
        bot.menu.image_method === 'upload' ? data.menu.upload_image_url : data.menu.input_image_url
      delete bot.menu.image
      delete bot.menu.upload_image_url
      delete bot.menu.input_image_url

      bot.use_default_topic = data.default_behavior === 'default_topic' ? true : false
      bot.default_topic_id = data.default_topic_id ? data.default_topic_id : null

      bot.use_feedback = data.use_feedback
      bot.feedback_question = data.feedback_question ? data.feedback_question : null
      bot.feedback_reply_yes = data.feedback_reply_yes ? data.feedback_reply_yes : null
      bot.feedback_reply_no = data.feedback_reply_no ? data.feedback_reply_no : null
      bot.is_optional_feedback = data.is_optional_feedback
      bot.is_required_feedback_reason = !data.is_optional_feedback ? data.is_required_feedback_reason : false

      bot.memory_definitions = data.memory_definitions

      bot.constant_definitions = (data.constant_definitions || []).map(constant_definition => {
        // Remove empty value
        if (lodash.isEmpty(constant_definition.value)) {
          return lodash.omit(constant_definition, 'value')
        } else {
          return constant_definition
        }
      })

      bot.use_office365 = !!data.use_office365
      bot.oauth_client_id_office365 = data.oauth_client_id_office365 ? data.oauth_client_id_office365 : null

      bot.use_salesforce = !!data.use_salesforce
      bot.oauth_client_id_salesforce = data.oauth_client_id_salesforce
        ? data.oauth_client_id_salesforce
        : null

      bot.use_gpt = !!data.use_gpt
      if (bot.use_gpt) {
        bot.openai_type = data.openai_type
        if (data.openai_type === 'default') {
          bot.openai_api_key = null
          bot.openai_endpoint = null
          bot.openai_embedding_deployment = null
          bot.openai_completion_deployment = null
          bot.openai_embedding_model = null
          bot.openai_completion_model = null
          bot.aws_region = null
          bot.aws_completion_model = null
          bot.aws_access_key_id = null
          bot.aws_secret_access_key = null
        } else if (data.openai_type === 'azure') {
          if (data.openai_api_key) {
            bot.openai_api_key = data.openai_api_key
          }
          bot.openai_endpoint = data.openai_endpoint
          bot.openai_embedding_deployment = data.openai_embedding_deployment
          bot.openai_completion_deployment = data.openai_completion_deployment
          bot.openai_embedding_model = null
          bot.openai_completion_model = null
          bot.aws_region = null
          bot.aws_completion_model = null
          bot.aws_access_key_id = null
          bot.aws_secret_access_key = null
        } else if (data.openai_type === 'openai') {
          if (data.openai_api_key) {
            bot.openai_api_key = data.openai_api_key
          }
          bot.openai_endpoint = null
          bot.openai_embedding_deployment = null
          bot.openai_completion_deployment = null
          bot.openai_embedding_model = data.openai_embedding_model
          bot.openai_completion_model = data.openai_completion_model
          bot.aws_region = null
          bot.aws_completion_model = null
          bot.aws_access_key_id = null
          bot.aws_secret_access_key = null
        } else if (data.openai_type === 'bedrock') {
          bot.openai_api_key = null
          bot.openai_endpoint = null
          bot.openai_embedding_deployment = null
          bot.openai_completion_deployment = null
          bot.openai_embedding_model = null
          bot.openai_completion_model = null
          bot.aws_region = data.aws_region
          bot.aws_completion_model = data.aws_completion_model
          if (data.aws_access_key_id) {
            bot.aws_access_key_id = data.aws_access_key_id
          }
          if (data.aws_secret_access_key) {
            bot.aws_secret_access_key = data.aws_secret_access_key
          }
        }
      }

      bot.enable_digit_masking = !!data.enable_digit_masking
      bot.digit_masking_length = data.digit_masking_length ? data.digit_masking_length : null

      bot.use_reset = !!data.use_reset
      if (bot.use_reset) {
        bot.reset_patterns = lodash.filter(data.reset_patterns, reset_pattern => {
          return reset_pattern.pattern && reset_pattern.pattern.trim()
        })
        bot.show_reset_in_balloon = !!data.show_reset_in_balloon
        if (bot.show_reset_in_balloon) {
          bot.reset_label_in_balloon = data.reset_label_in_balloon
        }
      }

      bot.use_undo = !!data.use_undo
      if (bot.use_undo) {
        bot.undo_patterns = lodash.filter(data.undo_patterns, undo_pattern => {
          return undo_pattern.pattern && undo_pattern.pattern.trim()
        })
        bot.show_undo_in_balloon = !!data.show_undo_in_balloon
        if (bot.show_undo_in_balloon) {
          bot.undo_label_in_balloon = data.undo_label_in_balloon
        }
      }

      bot.use_fulltext_search = !!data.use_fulltext_search
      bot.use_strict_matching = bot.use_fulltext_search ? !!data.use_strict_matching : false
      bot.use_advanced_machine_learning = !!data.use_advanced_machine_learning
      bot.language = data.language

      convertSystemVariablesOfBot(bot, true)

      const currentChannel = this.props.currentChannel
      // Need to self-manage processing flag due to redux form issue.
      // https://github.com/redux-form/redux-form/issues/3866
      this.setState({ resetting: true })
      return dispatch(updateBot(state.session.token, bot))
        .then(response => {
          const updatedBot = response.entities.bots[bot.id]
          const digit_masking_length = updatedBot.enable_digit_masking
            ? parseInt(updatedBot.digit_masking_length, 10)
            : null

          const features = {
            digitMaskingLength: digit_masking_length,
            reset: updatedBot.use_reset,
            undo: updatedBot.use_undo,
            language: updatedBot.language,
            callingOperatorMessage: updatedBot.calling_operator_message,
            operatorTypingMessage: updatedBot.operator_typing_message,
            callingOperatorButton: updatedBot.calling_operator_button,
          }
          dispatch(updateCurrentChannel(features))
        })
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() => {
          const isExceededMenuTextForLine = lengthWithoutVariable(data.menu.text) > 60
          if (isExceededMenuTextForLine) dispatch(addNotice('warn', t('bot.menu.exceededContentForLine')))
        })
        .then(() => this.props.reset())
        .then(() => checkApplications(bot.id, this.props.applications, dispatch, this.context))
        .then(() => dispatch(resetChannel(currentChannel)))
        .catch(() => {})
        .then(() => this.setState({ resetting: false }))
    } else {
      convertSystemVariablesOfBot(bot, true)

      return dispatch(createBot(state.session.token, bot))
        .then(json => router.push({ pathname: `/bots/${json.result}`, state: { ignoreBlocking: true } }))
        .then(() => cancelUrl.setRouteLeaveHook(this))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
    }
  }

  handleCopyDialogSubmit = data => {
    const { t } = this.context
    const { bot, dispatch } = this.props
    const state = this.context.store.getState()

    return dispatch(copyBot(state.session.token, bot.id, data.new_name, true)).then(json => {
      this.props.reset()
      const options = {
        linkTo: `/bots/${json.result}`,
        linkText: t('bot.copy.openOtherBot', { name: data.new_name }),
      }
      dispatch(addNotice('info', t('bot.copy.copySuccessMessage', { name: data.new_name }), options))
      this.setState({ isCopyDialogOpened: false })
    })
  }

  handleCopyDialogOpen = () => {
    const { t } = this.context
    const { dispatch } = this.props
    const state = this.context.store.getState()

    if (isDirtyForm(this)) {
      if (!window.confirm(t('bot.copy.confirmToDiscardChanges'))) {
        return
      }
    }
    return dispatch(fetchBots(state.session.token, {}, true)).then(() =>
      this.setState({ isCopyDialogOpened: true })
    )
  }

  handleCopyDialogClose = () => {
    this.setState({ isCopyDialogOpened: false })
  }

  renderCopyDialogForm = () => {
    return (
      <CopyDialog
        name={this.props.bot.name}
        notAllowedNames={lodash.map(this.props.bots, 'name')}
        onCopyDialogSubmit={this.handleCopyDialogSubmit}
        onCopyDialogClose={this.handleCopyDialogClose}
      />
    )
  }

  renderDeleteConfirmForm = () => {
    return (
      <DeleteConfirm
        targetType="bot"
        targetName={this.props.bot.name}
        onDeleteConfirmSubmit={this.handleDeleteConfirmSubmit}
        onDeleteConfirmClose={this.handleDeleteConfirmClose}
        errorMessage={this.state.deleteConfirmErrorMessage}
      />
    )
  }

  handleDeleteConfirmOpen = () => {
    this.setState({ isDeleteConfirmOpened: true })
  }

  handleDeleteConfirmClose = () => {
    this.setState({ isDeleteConfirmOpened: false })
  }

  handleDeleteConfirmSubmit = () => {
    const { t } = this.context
    const { bot, dispatch } = this.props
    const state = this.context.store.getState()
    const router = this.context.router
    dispatch(deleteBot(state.session.token, bot.id, true))
      .then(() => router.push({ pathname: '/bots', state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .catch(e => {
        this.setState({ deleteConfirmErrorMessage: e.message })
      })
  }

  handleExport = () => {
    const state = this.context.store.getState()
    const { t } = this.context
    const { dispatch, bot } = this.props
    if (!window.confirm(t('bot.exportDescription'))) return
    return dispatch(exportBot(state.session.token, bot.id)).then(blob => {
      // Download by special method if using IE
      if (new UAParser().getBrowser().name === 'IE') {
        window.navigator.msSaveBlob(blob, `${bot.name}.yaml`)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${bot.name}.yaml`
        link.click()
      }
    })
  }

  /**
   * Check the number current scenarios and move to /bots/:id/topics/new
   *
   * If the number reaches the maximum,
   * notice messages are shown on the current page
   *
   */
  handleAdd = target => {
    const { t, router } = this.context
    const { dispatch, bot, topics, faqs, faqPdfFiles, maxScenarios } = this.props
    if (target === 'scenario') {
      if (topics.length >= maxScenarios) {
        dispatch(addNotice('warn', t('topic.topicsLimitation', { maximum: maxScenarios })))
        return
      }
      router.push(`/bots/${bot.id}/topics/new`)
    } else if (target === 'faq') {
      if (bot.has_tagged_faq_pdf_files) {
        dispatch(addNotice('warn', t('faqpdf.disabledAddMessage')))
        return
      }
      if (faqs.length >= Config.maximumFaqs) {
        dispatch(addNotice('warn', t('faqpdf.faq.sourcesLimitation', { maximum: Config.maximumFaqs })))
        return
      }
      router.push(`/bots/${bot.id}/faqs/new`)
    } else if (target === 'faq_import') {
      if (bot.has_tagged_faq_pdf_files) {
        dispatch(addNotice('warn', t('faqpdf.disabledAddMessage')))
        return
      }
      if (faqs.length >= Config.maximumFaqs && faqPdfFiles.length >= Config.maximumPdfs) {
        const message = t('faqpdf.sourcesLimitation', {
          faqMaximum: Config.maximumFaqs,
          pdfMaximum: Config.maximumPdfs,
        })
        dispatch(addNotice('warn', message))
        return
      }
      router.push(`/bots/${bot.id}/faqs/import`)
    }
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  updateTopicsStyle = value => {
    const { dispatch } = this.props
    dispatch(updateTopicsStyle(value))
  }

  // check of file type and file size
  // maximum file size is 1MB(=1048576byte).
  onChangeMenuImageFile = (e, callback) => {
    const { dispatch } = this.props

    const callbackForUpload = uploadedImage => {
      const imageUrl = uploadedImage ? uploadedImage.image_url : null
      this.props.change('menu.upload_image_url', imageUrl)
      callback(imageUrl)
    }

    const state = this.context.store.getState()
    const image = e.target.files[0]
    const restrictions = {
      maxHeight: 1024,
      maxWidth: 1024,
      maxFileSize: 1048576,
      allowedFileFormats: ['image/jpeg', 'image/png'],
    }
    uploadImageFileWithValidation(
      dispatch,
      state.session.token,
      'choose_scenario',
      image,
      callbackForUpload,
      restrictions
    )
  }

  addErrorNoticeImageLoading = message => {
    this.props.dispatch(addNotice('warn', message))
  }

  resetMenuImage = () => {
    this.props.change('menu.upload_image_url', null)
  }

  render() {
    const { resetting } = this.state
    const {
      isFetching,
      submitting,
      handleSubmit,
      bot,
      domains,
      topics,
      availableTopics,
      faqs,
      faqPdfFiles,
      integrations,
      tasks,
      entities,
      pnpTopics,
      botEditForm,
      office365OAuthClients,
      salesforceOAuthClients,
      tableStates,
      topicsStyle,
      useExportImport,
      maxScenarios,
      params: { type },
    } = this.props

    return (
      <Loader loaded={!isFetching && !submitting && !resetting} type="show">
        <div className="dm-bot">
          <BotEditComponent
            type={type}
            bot={bot}
            domains={domains}
            topics={topics}
            availableTopics={availableTopics}
            faqs={faqs}
            faqPdfFiles={faqPdfFiles}
            integrations={integrations}
            tasks={tasks}
            entities={entities}
            pnpTopics={pnpTopics}
            isSubmitting={submitting}
            handleSubmit={handleSubmit}
            handleSave={handleSubmit(this.handleSave)}
            handleCopy={this.handleCopyDialogOpen}
            handleDelete={this.handleDeleteConfirmOpen}
            handleExport={this.handleExport}
            handleAdd={this.handleAdd}
            botEditForm={botEditForm}
            office365OAuthClients={office365OAuthClients}
            salesforceOAuthClients={salesforceOAuthClients}
            tableStates={tableStates}
            updateTableState={this.updateTableState}
            topicsStyle={topicsStyle}
            useExportImport={useExportImport}
            maxScenarios={maxScenarios}
            updateTopicsStyle={this.updateTopicsStyle}
            resetLeaveHook={() => cancelUrl.setRouteLeaveHook(this)}
            onChangeMenuImageFile={this.onChangeMenuImageFile}
            addErrorNoticeImageLoading={this.addErrorNoticeImageLoading}
            resetMenuImage={this.resetMenuImage}
          />
          {this.state.isCopyDialogOpened && this.renderCopyDialogForm()}
          {this.state.isDeleteConfirmOpened && this.renderDeleteConfirmForm()}
          {this.renderSimulator()}
        </div>
      </Loader>
    )
  }

  renderSimulator() {
    const { t } = this.context
    const { bot, domains } = this.props
    if (!bot.id || !bot.application_token) return null

    return (
      <Sidebar titles={[t('simulator.title')]}>
        <Simulator tabs={['topicClassifier', 'chat']} bot={bot} domain={domains[0]} />
      </Sidebar>
    )
  }
}

const BotEditForm = reduxForm({
  form: 'BotEdit',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
  // Execute "validate" when change topics / domains in props
  shouldError: ({ props, nextProps }) => {
    if (!props || !nextProps) return true
    if (props.domains.length !== nextProps.domains.length) return true
    if (props.topics.length !== nextProps.topics.length) return true
    return false
  },
})(BotEdit)

export const mapStateToProps = (state, props) => {
  const account = lodash.first(Object.values(state.entities.accounts)) || {}
  const applications = lodash.filter(state.entities.applications)
  const bot = state.entities.bots[props.params.id] || {}
  const bots = lodash.filter(state.entities.bots)
  const domains = lodash.filter(state.entities.domains, { bot_id: bot.id })
  const domainIds = domains.map(domain => domain.id)
  const topics = lodash.filter(state.entities.topics, topic => lodash.includes(domainIds, topic.domain_id))
  const normalTopics = lodash.filter(topics, { type: 'normal' })
  const availableTopics = lodash.filter(normalTopics, { is_draft: false })
  const pnpTopics = lodash.filter(topics, { type: 'phone_number_push' })
  const faqs = lodash.filter(state.entities.faqs, { bot_id: bot.id })
  const faqPdfFiles = lodash.filter(state.entities.faq_pdf_files, { bot_id: bot.id })
  const integrations = lodash.filter(state.entities.integrations, { bot_id: bot.id })
  const tasks = lodash.filter(state.entities.tasks, { bot_id: bot.id })
  const entities = lodash.filter(state.entities.entities, { bot_id: bot.id })

  convertSystemVariablesOfBot(bot, false)

  const initialValues = {
    ...bot,
    domains,
  }

  if (!lodash.isEmpty(bot.menu)) {
    initialValues.menu = {
      ...bot.menu,
      image_method: bot.menu.image_method || 'upload',
    }

    if (initialValues.menu.image_method === 'upload') {
      initialValues.menu.upload_image_url = initialValues.menu.image_url
    } else {
      initialValues.menu.input_image_url = initialValues.menu.image_url
    }
  }

  const botEditForm = state.form.BotEdit || {}

  // enable_operator_business_hours is undefined if don't finish to fetch bot
  if (bot.enable_operator_business_hours === true) {
    const operator_business_hours = lodash.cloneDeep(bot.operator_business_hours) || {}
    if (operator_business_hours.is_full_time) {
      operator_business_hours.is_full_time = 'true'
    } else {
      operator_business_hours.is_full_time = 'false'
    }
    initialValues.operator_business_hours = operator_business_hours
  } else if (bot.enable_operator_business_hours === false) {
    initialValues.operator_business_hours = { is_full_time: 'true' }
  }

  if (bot.classification_fails_count_for_topic === null) {
    initialValues.classification_fails_count_for_topic = 3
  }
  if (bot.classification_fails_count_for_faq === null) {
    initialValues.classification_fails_count_for_faq = 3
  }

  const oauthIds = state.pagination.oauth_clients.ids || []
  const oauthClients = oauthIds.map(id => state.entities.oauth_clients[id])
  const office365OAuthClients = lodash.filter(oauthClients, { type: 'office365' })
  const salesforceOAuthClients = lodash.filter(oauthClients, { type: 'salesforce' })

  const tableStates = {
    topic: state.table[`${window.location.pathname}#TopicList`],
    faq: state.table[`${window.location.pathname}#FaqList`],
    integration: state.table[`${window.location.pathname}#ThirdPartyServiceList`],
    task: state.table[`${window.location.pathname}#TaskList`],
    entity: state.table[`${window.location.pathname}#UserDictionaryList`],
    pnpTopic: state.table[`${window.location.pathname}#PnpTopicList`],
  }

  return {
    isFetching: isFetching(state),
    botEditForm: botEditForm.values || {},
    initialValues,
    applications,
    bot,
    bots,
    domains,
    faqs,
    faqPdfFiles,
    topics: normalTopics,
    availableTopics,
    pnpTopics: pnpTopics,
    integrations,
    tasks,
    currentChannel: state.chat.currentChannel,
    entities,
    office365OAuthClients: office365OAuthClients,
    salesforceOAuthClients: salesforceOAuthClients,
    tableStates,
    topicsStyle: state.session.topicsStyle,
    useExportImport: account.use_export_import,
    maxScenarios: account.max_scenarios,
  }
}

export default connect(mapStateToProps)(BotEditForm)
