import React, { Component } from 'react'
import Stickyfill from 'stickyfilljs'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { fetchApplication } from '../../actions/application'
import { fetchBot } from '../../actions/bot'
import { fetchTopic } from '../../actions/topic'
import { fetchInterruption } from '../../actions/interruption'
import { fetchIntegration } from '../../actions/integration'
import { fetchTask } from '../../actions/task'
import { fetchOAuthClient } from '../../actions/oauth_client'
import { fetchEntity } from '../../actions/entity'
import { fetchFaq } from '../../actions/faq'
import { fetchFaqPdfFile } from '../../actions/faq_pdf_file'
import { fetchFaqItem } from '../../actions/faq_item'
import { fetchEndpoint } from '../../actions/endpoint'
import { fetchUser } from '../../actions/user'
import { fetchDeliveryJob } from '../../actions/delivery'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { isFetching } from '../../helpers/selector'

const timers = {}

class Breadcrumbs extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    dispatch: PropTypes.func,
    session: PropTypes.object,
    generator: PropTypes.func,
    location: PropTypes.object.isRequired,
  }

  static defaultProps = {
    generator: () => [],
  }

  componentWillUpdate(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      Object.keys(timers).forEach(key => {
        clearTimeout(timers[key])
        delete timers[key]
      })
    }
  }

  componentDidUpdate(prevProps) {
    //  For IE11/Edge compatibility
    if (!prevProps.session.token && this.props.session.token) {
      Stickyfill.refreshAll()
    }
  }

  render() {
    const { t } = this.context
    const { session, generator, location, dispatch } = this.props

    if (!session.loaded) return null

    const breadcrumbs = [{ to: '/', name: t('menu.top') }, ...generator(location, t, dispatch)]
    const list = breadcrumbs.map((e, index) => {
      if (e.to) {
        return (
          <li key={index}>
            <Link to={e.to} query={e.query}>
              {e.name}
            </Link>
          </li>
        )
      } else {
        return <li key={index}>{e.name}</li>
      }
    })
    return (
      <div className="dm-breadcrumb">
        <div className="container">
          <div className="row">
            <ul className="breadcrumb">{list}</ul>
          </div>
        </div>
      </div>
    )
  }
}

const fetch = (state, dispatch, type, id, fetcher) => {
  if (!id) return
  const uniqueKey = `${type}:${id}`
  const entity = state.entities[type][id]
  if (entity || isFetching(state, type)) {
    clearTimeout(timers[uniqueKey])
    delete timers[uniqueKey]
    return entity
  }
  if (timers[uniqueKey]) return

  timers[uniqueKey] = setTimeout(() => {
    delete timers[uniqueKey]
    dispatch(fetcher(state.session.token))
  }, 1000)
}

const bindFetcher = (fetcher, ...args) => {
  return token => fetcher(token, ...args, () => {})
}

const breadcrumbsGenerator = (location, state) => {
  const mapper = {}
  mapper['/operator'] = (location, t) => [{ name: t('menu.submenu.operator') }, { name: t('menu.operator') }]
  mapper['/applications$'] = (location, t) => [
    { name: t('menu.submenu.applications') },
    { name: t('menu.listApplications') },
  ]
  mapper['/bots$'] = (location, t) => [{ name: t('menu.submenu.bots') }, { name: t('menu.listBots') }]
  mapper['/analytics$'] = (location, t) => [
    { name: t('menu.submenu.analytics') },
    { name: t('menu.statistics') },
  ]
  mapper['/users$'] = (location, t) => [{ name: t('menu.submenu.manageAccount') }, { name: t('menu.users') }]
  mapper['/oauth_clients$'] = (location, t) => [
    { name: t('menu.submenu.manageAccount') },
    { name: t('menu.oauthClients') },
  ]
  mapper['/endpoints$'] = (location, t) => [{ name: t('menu.endpoints') }]
  mapper['/template$'] = (location, t) => [{ name: t('menu.template') }]

  mapper['/applications/(\\d+)'] = (location, t, dispatch) => {
    const id = location.pathname.match('/applications/(\\d+)')[1]
    const fetcher = bindFetcher(fetchApplication, id, false)
    const application = fetch(state, dispatch, 'applications', id, fetcher)
    return [
      { name: t('menu.submenu.applications') },
      { to: '/applications', name: t('menu.listApplications') },
      { name: application ? application.name : '...' },
    ]
  }

  mapper['/applications/new'] = (location, t) => [
    { name: t('menu.submenu.applications') },
    { name: t('menu.createApplication') },
  ]

  mapper['/bots/(\\d+)/?(topics|faqs|integrations|tasks|entities|pnp_topics)?$'] = (
    location,
    t,
    dispatch
  ) => {
    const id = location.pathname.match('/bots/(\\d+)')[1]
    const fetcher = bindFetcher(fetchBot, id, false)
    const bot = fetch(state, dispatch, 'bots', id, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { name: bot ? bot.name : '...' },
    ]
  }

  mapper['/bots/create'] = (location, t) => {
    return [{ to: '/bots/create', name: t('menu.submenu.bots') }]
  }

  mapper['/bots/new'] = (location, t) => {
    return [{ to: '/bots/create', name: t('menu.submenu.bots') }, { name: t('common.new') }]
  }

  mapper['/bots/import'] = (location, t) => {
    return [{ to: '/bots/create', name: t('menu.submenu.bots') }, { name: t('bot.templates') }]
  }

  mapper['/bots/(\\d+)/import'] = (location, t, dispatch) => {
    const id = location.pathname.match('/bots/(\\d+)')[1]
    const fetcher = bindFetcher(fetchBot, id, false)
    const bot = fetch(state, dispatch, 'bots', id, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: bot && `/bots/${bot.id}/topics`, name: bot ? bot.name : '...' },
      { name: t('bot.import.bulkImport') },
    ]
  }

  mapper['/bots/(\\d+)/topics/(\\d+)'] = (location, t, dispatch) => {
    const [, bot_id, id] = location.pathname.match('/bots/(\\d+)/topics/(\\d+)')

    const topicFetcher = bindFetcher(fetchTopic, id)
    const topic = fetch(state, dispatch, 'topics', id, topicFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id, false)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/topics`, name: bot ? bot.name : '...' },
      { name: topic ? topic.name : '...' },
    ]
  }

  mapper['/bots/(\\d+)/topics/new'] = (location, t, dispatch) => {
    const bot_id = location.pathname.match('/bots/(\\d+)/topics/new')[1]
    const fetcher = bindFetcher(fetchBot, bot_id, false)
    const bot = fetch(state, dispatch, 'bots', bot_id, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/topics`, name: bot ? bot.name : '...' },
      { name: t('common.new') },
    ]
  }

  mapper['/bots/(\\d+)/faqs/(\\d+)/items/(\\d+)'] = (location, t, dispatch) => {
    const [, bot_id, faq_id, id] = location.pathname.match('/bots/(\\d+)/faqs/(\\d+)/items/(\\d+)')

    const itemFetcher = bindFetcher(fetchFaqItem, faq_id, id)
    const item = fetch(state, dispatch, 'faq_items', id, itemFetcher)

    const faqFetcher = bindFetcher(fetchFaq, faq_id, false)
    const faq = fetch(state, dispatch, 'faqs', faq_id, faqFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id, false)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}`, name: bot ? bot.name : '...' },
      { to: `/bots/${bot_id}/faqs/${faq_id}`, name: faq ? faq.name : '...' },
      { name: item ? item.name : '...' },
    ]
  }

  mapper['/bots/(\\d+)/faqs/(\\d+)/items/new'] = (location, t, dispatch) => {
    const [, bot_id, id] = location.pathname.match('/bots/(\\d+)/faqs/(\\d+)/items/new')

    const faqFetcher = bindFetcher(fetchFaq, id, false)
    const faq = fetch(state, dispatch, 'faqs', id, faqFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id, false)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}`, name: bot ? bot.name : '...' },
      { to: `/bots/${bot_id}/faqs/${id}`, name: faq ? faq.name : '...' },
      { name: t('common.new') },
    ]
  }

  mapper['/bots/(\\d+)/faqs/(\\d+)/generator/groups/\\d+'] = (location, t, dispatch) => {
    const [, bot_id, faq_id] = location.pathname.match('/bots/(\\d+)/faqs/(\\d+)/generator/groups/\\d+')

    const faqFetcher = bindFetcher(fetchFaq, faq_id, false)
    const faq = fetch(state, dispatch, 'faqs', faq_id, faqFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id, false)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}`, name: bot ? bot.name : '...' },
      { to: `/bots/${bot_id}/faqs/${faq_id}`, name: faq ? faq.name : '...' },
      { to: `/bots/${bot_id}/faqs/${faq_id}/generator/groups`, name: t('inquiry.title') },
      { name: t('common.detail') },
    ]
  }

  mapper['/bots/(\\d+)/faqs/(\\d+)/generator'] = (location, t, dispatch) => {
    const [, bot_id, id] = location.pathname.match('/bots/(\\d+)/faqs/(\\d+)/generator')

    const faqFetcher = bindFetcher(fetchFaq, id, false)
    const faq = fetch(state, dispatch, 'faqs', id, faqFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id, false)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}`, name: bot ? bot.name : '...' },
      { to: `/bots/${bot_id}/faqs/${id}`, name: faq ? faq.name : '...' },
      { name: t('inquiry.title') },
    ]
  }

  mapper['/bots/(\\d+)/faqs/(\\d+)'] = (location, t, dispatch) => {
    const [, bot_id, id] = location.pathname.match('/bots/(\\d+)/faqs/(\\d+)')

    const faqFetcher = bindFetcher(fetchFaq, id)
    const faq = fetch(state, dispatch, 'faqs', id, faqFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/faqs`, name: bot ? bot.name : '...' },
      { name: faq ? faq.name : '...' },
    ]
  }

  mapper['/bots/(\\d+)/faqs/new'] = (location, t, dispatch) => {
    const bot_id = location.pathname.match('/bots/(\\d+)/faqs/new')[1]
    const fetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/faqs`, name: bot ? bot.name : '...' },
      { name: t('common.new') },
    ]
  }

  mapper['/bots/(\\d+)/faqs/import'] = (location, t, dispatch) => {
    const bot_id = location.pathname.match('/bots/(\\d+)/faqs/import')[1]
    const fetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/faqs`, name: bot ? bot.name : '...' },
      { name: t('common.import') },
    ]
  }

  mapper['/bots/(\\d+)/faq_pdf_files/(\\d+)'] = (location, t, dispatch) => {
    const [, bot_id, id] = location.pathname.match('/bots/(\\d+)/faq_pdf_files/(\\d+)')

    const faqPdfFileFetcher = bindFetcher(fetchFaqPdfFile, id)
    const faqPdfFile = fetch(state, dispatch, 'faq_pdf_files', id, faqPdfFileFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)

    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/faqs`, name: bot ? bot.name : '...' },
      { name: faqPdfFile ? faqPdfFile.name : '...' },
    ]
  }

  mapper['/bots/(\\d+)/integrations/(\\d+)'] = (location, t, dispatch) => {
    const [, bot_id, id] = location.pathname.match('/bots/(\\d+)/integrations/(\\d+)')

    const integrationFetcher = bindFetcher(fetchIntegration, id)
    const integration = fetch(state, dispatch, 'integrations', id, integrationFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/integrations`, name: bot ? bot.name : '...' },
      { name: integration ? integration.name : '...' },
    ]
  }

  mapper['/bots/(\\d+)/integrations/new'] = (location, t, dispatch) => {
    const bot_id = location.pathname.match('/bots/(\\d+)/integrations/new')[1]
    const fetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/integrations`, name: bot ? bot.name : '...' },
      { name: t('common.new') },
    ]
  }

  mapper['/bots/(\\d+)/tasks/(\\d+)'] = (location, t, dispatch) => {
    const [, bot_id, id] = location.pathname.match('/bots/(\\d+)/tasks/(\\d+)')

    const taskFetcher = bindFetcher(fetchTask, id)
    const task = fetch(state, dispatch, 'tasks', id, taskFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/tasks`, name: bot ? bot.name : '...' },
      { name: task ? task.name : '...' },
    ]
  }

  mapper['/bots/(\\d+)/tasks/new'] = (location, t, dispatch) => {
    const bot_id = location.pathname.match('/bots/(\\d+)/tasks/new')[1]
    const fetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/tasks`, name: bot ? bot.name : '...' },
      { name: t('common.new') },
    ]
  }

  mapper['/bots/(\\d+)/entities/(\\d+)'] = (location, t, dispatch) => {
    const [, bot_id, id] = location.pathname.match('/bots/(\\d+)/entities/(\\d+)')

    const entityFetcher = bindFetcher(fetchEntity, id)
    const entity = fetch(state, dispatch, 'entities', id, entityFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/entities`, name: bot ? bot.name : '...' },
      { name: entity ? entity.name : '...' },
    ]
  }

  mapper['/bots/(\\d+)/entities/new'] = (location, t, dispatch) => {
    const bot_id = location.pathname.match('/bots/(\\d+)/entities/new')[1]
    const fetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/entities`, name: bot ? bot.name : '...' },
      { name: t('common.new') },
    ]
  }

  mapper['/bots/(\\d+)/pnp_topics/(\\d+)'] = (location, t, dispatch) => {
    const [, bot_id, id] = location.pathname.match('/bots/(\\d+)/pnp_topics/(\\d+)')

    const topicFetcher = bindFetcher(fetchTopic, id)
    const topic = fetch(state, dispatch, 'topics', id, topicFetcher)

    const botFetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/pnp_topics`, name: bot ? bot.name : '...' },
      { name: topic ? topic.name : '...' },
    ]
  }

  mapper['/bots/(\\d+)/pnp_topics/new'] = (location, t, dispatch) => {
    const bot_id = location.pathname.match('/bots/(\\d+)/pnp_topics/new')[1]
    const fetcher = bindFetcher(fetchBot, bot_id)
    const bot = fetch(state, dispatch, 'bots', bot_id, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${bot_id}/pnp_topics`, name: bot ? bot.name : '...' },
      { name: t('common.new') },
    ]
  }

  mapper['/interruptions/(\\d+)'] = (location, t, dispatch) => {
    const id = location.pathname.match('/interruptions/(\\d+)')[1]

    const interruptionFetcher = bindFetcher(fetchInterruption, id)
    const interruption = fetch(state, dispatch, 'interruptions', id, interruptionFetcher)

    const botId = (interruption || {}).bot_id
    const botFetcher = bindFetcher(fetchBot, botId)
    const bot = fetch(state, dispatch, 'bots', botId, botFetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: interruption && `/bots/${interruption.bot_id}`, name: bot ? bot.name : '...' },
      { name: interruption ? interruption.keyword : '...' },
    ]
  }

  mapper['/interruptions/new'] = (location, t, dispatch) => {
    const botId = queryString.parse(location.search).botId
    const fetcher = bindFetcher(fetchBot, botId)
    const bot = fetch(state, dispatch, 'bots', botId, fetcher)
    return [
      { name: t('menu.submenu.bots') },
      { to: '/bots', name: t('menu.listBots') },
      { to: `/bots/${botId}`, name: bot ? bot.name : '...' },
      { name: t('common.new') },
    ]
  }

  mapper['/analytics/(\\d+)'] = (location, t, dispatch) => {
    const id = location.pathname.match('/analytics/(\\d+)')[1]
    const fetcher = bindFetcher(fetchApplication, id)
    const application = fetch(state, dispatch, 'applications', id, fetcher)
    return [
      { name: t('menu.submenu.analytics') },
      { to: '/analytics', name: t('menu.statistics') },
      { name: application ? application.name : '...' },
    ]
  }

  mapper['/users/(\\d+)'] = (location, t, dispatch) => {
    const id = location.pathname.match('/users/(\\d+)')[1]
    const fetcher = bindFetcher(fetchUser, id)
    const user = fetch(state, dispatch, 'users', id, fetcher)
    if (state.session.role === 'owner') {
      return [
        { name: t('menu.submenu.manageAccount') },
        { to: '/users', name: t('menu.users') },
        { name: user ? user.name : '...' },
      ]
    } else {
      return [
        { name: t('menu.submenu.manageAccount') },
        { name: t('menu.users') },
        { name: user ? user.name : '...' },
      ]
    }
  }

  mapper['/users/new'] = (location, t) => {
    return [
      { name: t('menu.submenu.manageAccount') },
      { to: '/users', name: t('menu.users') },
      { name: t('common.new') },
    ]
  }

  mapper['/endpoints/(\\d+)'] = (location, t, dispatch) => {
    const id = location.pathname.match('/endpoints/(\\d+)')[1]
    const fetcher = bindFetcher(fetchEndpoint, id)
    const endpoint = fetch(state, dispatch, 'endpoints', id, fetcher)
    return [{ to: '/endpoints', name: t('menu.endpoints') }, { name: endpoint ? endpoint.username : '...' }]
  }

  mapper['/endpoints/new'] = (location, t) => [
    { to: '/endpoints', name: t('menu.endpoints') },
    { name: t('common.new') },
  ]

  mapper['/account'] = (location, t) => [
    { name: t('menu.submenu.manageAccount') },
    { name: t('menu.account') },
  ]

  mapper['/email_addresses'] = (location, t) => [
    { name: t('menu.submenu.manageAccount') },
    { name: t('menu.emailAddresses') },
  ]

  mapper['/oauth_clients/(\\d+)'] = (location, t, dispatch) => {
    const id = location.pathname.match('/oauth_clients/(\\d+)')[1]
    const fetcher = bindFetcher(fetchOAuthClient, id)
    const oauthClient = fetch(state, dispatch, 'oauth_clients', id, fetcher)
    return [
      { name: t('menu.submenu.manageAccount') },
      { to: '/oauth_clients', name: t('menu.oauthClients') },
      { name: oauthClient ? oauthClient.name : '...' },
    ]
  }

  mapper['/oauth_clients/new'] = (location, t) => [
    { name: t('menu.submenu.manageAccount') },
    { to: '/oauth_clients', name: t('menu.oauthClients') },
    { name: t('common.new') },
  ]

  mapper['/delivery/(\\d+)/jobs$'] = (location, t, dispatch) => {
    const application_id = location.pathname.match('/delivery/(\\d+)/jobs')[1]
    const fetcher = bindFetcher(fetchApplication, application_id, false)
    const application = fetch(state, dispatch, 'applications', application_id, fetcher)
    return [
      { name: t('menu.submenu.analytics') },
      { to: '/delivery', name: t('menu.delivery') },
      { name: application ? application.name : '...' },
    ]
  }

  mapper['/delivery/(\\d+)/jobs/(\\d+/copy|new)'] = (location, t, dispatch) => {
    const application_id = location.pathname.match('/delivery/(\\d+)/jobs/(\\d+/copy|new)')[1]
    const fetcher = bindFetcher(fetchApplication, application_id, false)
    const application = fetch(state, dispatch, 'applications', application_id, fetcher)
    return [
      { name: t('menu.submenu.analytics') },
      { to: '/delivery', name: t('menu.delivery') },
      { to: `/delivery/${application_id}/jobs`, name: application ? application.name : '...' },
      { name: t('common.new') },
    ]
  }

  mapper['/delivery/(\\d+)/jobs/(\\d+)'] = (location, t, dispatch) => {
    const [, application_id, id] = location.pathname.match('/delivery/(\\d+)/jobs/(\\d+)')

    const jobFetcher = bindFetcher(fetchDeliveryJob, id)
    const job = fetch(state, dispatch, 'delivery_jobs', id, jobFetcher)

    const applicationFetcher = bindFetcher(fetchApplication, application_id, false)
    const application = fetch(state, dispatch, 'applications', application_id, applicationFetcher)
    return [
      { name: t('menu.submenu.analytics') },
      { to: '/delivery', name: t('menu.delivery') },
      { to: `/delivery/${application_id}/jobs`, name: application ? application.name : '...' },
      { name: job ? job.name : '...' },
    ]
  }

  mapper['/delivery'] = (location, t) => [{ name: t('menu.submenu.analytics') }, { name: t('menu.delivery') }]

  for (let i = 0; i < Object.keys(mapper).length; i++) {
    const pattern = Object.keys(mapper)[i]
    const generator = mapper[pattern]
    if (location.pathname.match(pattern)) return generator
  }
}

const mapStateToProps = (state, props) => {
  const generator = breadcrumbsGenerator(props.location, state) || (() => [])
  return {
    generator,
  }
}

export default connect(mapStateToProps)(Breadcrumbs)
