import lodash from 'lodash'
import { isURL } from 'validator'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import Sticky from 'react-sticky-state'

import { InputField, FileField, TextAreaField } from '../../components/common/fields/FormFields'
import Sidebar from '../../components/common/Sidebar'
import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { Simulator } from '../simulator/Simulator'
import Trainer from '../bot/Trainer'

import { addNotice } from '../../actions/notice'
import { fetchBot } from '../../actions/bot'
import {
  fetchFaqPdfFile,
  deleteFaqPdfFile,
  updateFaqPdfFile,
  deleteFaqPdfFileRevision,
} from '../../actions/faq_pdf_file'
import { fetchApplications } from '../../actions/application'

import { isFetching } from '../../helpers/selector'
import { checkApplications } from '../../helpers/checkApplications'
import cancelUrl from '../../helpers/cancelurl'
import { convertLineBreak } from '../../helpers/lineBreakConverter'
import { getCredentials } from '../../helpers/sessionHelper'

const validate = data => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else {
    if (data.name.length > 255) {
      errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
  }
  if (data.link && data.link.length > 255) {
    errors.link = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  }
  if (data.description && data.description.length > 255) {
    errors.description = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  }

  return errors
}

export class FaqPdfFileEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    bot: PropTypes.object,
    faqPdfFile: PropTypes.object.isRequired,
    applications: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      bot_id: PropTypes.string.isRequired,
      faq_pdf_file_id: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
    submitting: PropTypes.bool,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const {
      dispatch,
      params: { bot_id, faq_pdf_file_id },
    } = this.props
    dispatch(fetchApplications(state.session.token, bot_id))
    dispatch(fetchBot(state.session.token, bot_id))
    dispatch(fetchFaqPdfFile(state.session.token, faq_pdf_file_id))
    cancelUrl.setRouteLeaveHook(this)
  }

  handleSave = (data, dispatch) => {
    const state = this.context.store.getState()
    const { t } = this.context
    const {
      applications,
      faqPdfFile,
      params: { bot_id },
    } = this.props

    if (data.link || data.description) {
      if (!data.upload_file || !data.upload_file[0]) {
        dispatch(addNotice('error', t('faqpdf.faqPdfFile.noFileSelectedError')))
        return
      }
    }
    if (data.upload_file && data.upload_file[0]) {
      const fileSize = data.upload_file[0].size / 1024 / 1024
      if (fileSize > 40) {
        dispatch(addNotice('error', t('validate.invalidFileSize', { size: 40 })))
        return
      }
    }

    const body = new FormData()
    body.append('name', data.name)
    if (data.upload_file) {
      body.append('file', data.upload_file[0])
      body.append('link', data.link || '')
      body.append('description', data.description || '')
    }
    return dispatch(updateFaqPdfFile(state.session.token, body, faqPdfFile.id))
      .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
      .then(() => dispatch(fetchBot(state.session.token, bot_id)))
      .then(() => checkApplications(bot_id, applications, dispatch, this.context))
      .finally(() => {
        document.getElementById('upload_file').value = ''
        this.props.change('upload_file', '')
        this.props.change('link', '')
        this.props.change('description', '')
      })
  }

  handleDelete = (ignoreConfirmation = false) => {
    const state = this.context.store.getState()
    const { t, router } = this.context
    const {
      applications,
      faqPdfFile,
      dispatch,
      params: { bot_id },
    } = this.props

    if (!ignoreConfirmation) {
      if (
        !window.confirm(
          t('common.deleteConfirmMessage', { type: t('faqpdf.faqPdfFile.name'), name: faqPdfFile.name })
        )
      )
        return
    }

    dispatch(deleteFaqPdfFile(state.session.token, faqPdfFile.id))
      .then(() => router.push({ pathname: `/bots/${bot_id}/faqs`, state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .then(() => checkApplications(bot_id, applications, dispatch, this.context))
  }

  handleDeleteRevision = id => {
    const state = this.context.store.getState()
    const { t } = this.context
    const {
      applications,
      faqPdfFile,
      dispatch,
      params: { bot_id },
    } = this.props
    const { timezone } = getCredentials(this.context)
    const revision = lodash.find(faqPdfFile.revisions, { id: id })

    if (faqPdfFile.revisions.length === 1) {
      if (
        window.confirm(t('faqpdf.faqPdfFile.deleteLastRevisionConfirmMessage', { name: faqPdfFile.name }))
      ) {
        this.handleDelete(true)
      }
      return
    }

    const params = {
      filename: revision.filename,
      size: (revision.size / 1024).toFixed() + 'KB',
      lastUpdatedAt: moment.tz(revision.file_last_updated_at, timezone).format('YYYY/MM/DD HH:mm'),
    }
    if (!window.confirm(t('faqpdf.faqPdfFile.deleteRevisionConfirmMessage', params))) return

    dispatch(deleteFaqPdfFileRevision(state.session.token, faqPdfFile.id, revision.id))
      .then(() => dispatch(fetchFaqPdfFile(state.session.token, faqPdfFile.id)))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .then(() => checkApplications(bot_id, applications, dispatch, this.context))
  }

  renderLink = link => {
    if (!link) return link

    const isUrl = isURL(link, {
      protocols: ['http', 'https'],
      require_tld: false,
      require_protocol: true,
    })
    if (isUrl) {
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      )
    } else {
      return link
    }
  }

  render() {
    const { t } = this.context
    const { bot, faqPdfFile, isFetching, submitting, handleSubmit } = this.props
    const { timezone } = getCredentials(this.context)

    const handleSave = handleSubmit(this.handleSave)
    const revisions = lodash.map(faqPdfFile.revisions, revision => {
      return (
        <tr key={revision.id} className="row">
          <td>{revision.filename}</td>
          <td>{this.renderLink(revision.link)}</td>
          <td>{convertLineBreak(revision.description, 'faqPdfFile')}</td>
          <td>{(revision.size / 1024).toFixed()}KB</td>
          <td>{moment.tz(revision.file_last_updated_at, timezone).format('YYYY/MM/DD HH:mm')}</td>
          <td className="delete">
            <button
              type="button"
              className="dm-btn btn btn-danger btn-icon-delete"
              onClick={() => {
                this.handleDeleteRevision(revision.id)
              }}
            />
          </td>
        </tr>
      )
    })

    return (
      <Loader loaded={!isFetching && !submitting} type="show">
        <form className="text-left" encType="multipart/form-data" onSubmit={handleSave}>
          {/* Faq PDF File name */}
          <div className="form-group">
            <LabelWithTooltip htmlFor="name" className="dm-title" name="faqpdf.faqPdfFile.name" />
            <Field
              name="name"
              type="text"
              className="form-control dm-form-control"
              maxLength="255"
              component={InputField}
            />
          </div>
          <LabelWithTooltip className="dm-title" name="faqpdf.faqPdfFile.detail" />
          <div className="small">
            <table className="table table-sm table-bordered">
              <thead>
                <tr className="row">
                  <th>{t('faqpdf.faqPdfFile.filename')}</th>
                  <th>{t('faqpdf.faqPdfFile.link')}</th>
                  <th>{t('faqpdf.faqPdfFile.description')}</th>
                  <th>{t('faqpdf.faqPdfFile.size')}</th>
                  <th>{t('faqpdf.faqPdfFile.lastModified')}</th>
                  <th>{t('common.delete')}</th>
                </tr>
              </thead>
              <tbody>{revisions}</tbody>
            </table>
          </div>
          <div>
            <Collapsible
              trigger={<span>{t('faqpdf.faqPdfFile.revise')}</span>}
              transitionTime={300}
              overflowWhenOpen="visible"
            >
              <div className="with-indent">
                {/* upload file */}
                <div className="form-group">
                  <LabelWithTooltip
                    htmlFor="upload_file"
                    className="dm-title"
                    name="faqpdf.faqPdfFile.data"
                  />
                  <Field
                    type="file"
                    name="upload_file"
                    className="form-control dm-form-control"
                    accept=".pdf"
                    component={FileField}
                  />
                </div>
                {/* link to original file */}
                <div className="form-group">
                  <LabelWithTooltip htmlFor="link" className="dm-title" name="faqpdf.faqPdfFile.link" />
                  <Field
                    type="text"
                    name="link"
                    className="form-control dm-form-control"
                    maxLength="255"
                    component={InputField}
                  />
                </div>
                {/* description */}
                <div className="form-group">
                  <LabelWithTooltip
                    htmlFor="description"
                    className="dm-title"
                    name="faqpdf.faqPdfFile.description"
                  />
                  <Field
                    name="description"
                    minRows={4}
                    className="form-control dm-form-control"
                    maxLength="255"
                    component={TextAreaField}
                  />
                </div>
              </div>
            </Collapsible>
          </div>
          {/* Buttons to edit faq (sticked to the page buttom) */}
          <Sticky>
            <div className="sticky bottom">
              <div className="dm-sticky-buttons">
                <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                  {t('common.save')}
                </button>
                <Trainer
                  bot={bot}
                  handleSubmit={handleSubmit}
                  handleSave={handleSave}
                  isSubmitting={submitting}
                />
                <button
                  type="button"
                  className="btn btn-danger dm-btn"
                  onClick={this.handleDelete}
                  disabled={submitting}
                >
                  {t('common.delete')}
                </button>
              </div>
            </div>
          </Sticky>
        </form>
        <Sidebar titles={[t('simulator.title')]}>
          <Simulator tabs={['chat']} bot={bot} />
        </Sidebar>
      </Loader>
    )
  }
}

const FaqPdfFileEditForm = reduxForm({
  form: 'FaqPdfFileEdit',
  enableReinitialize: true,
  validate,
})(FaqPdfFileEdit)

export const mapStateToProps = (state, props) => {
  const applications = lodash.filter(state.entities.applications)
  const bot = state.entities.bots[props.params.bot_id] || {}
  const faqPdfFile = state.entities.faq_pdf_files[props.params.faq_pdf_file_id] || {}

  return {
    bot,
    faqPdfFile,
    applications,
    isFetching: isFetching(state, ['faq_pdf_files']),
    initialValues: { name: faqPdfFile.name },
  }
}

export default connect(mapStateToProps)(FaqPdfFileEditForm)
