import lodash from 'lodash'
import uuid from 'uuid'
import moment from 'moment'
import queryString from 'query-string'

import request from '../helpers/requestMessagingAPI'

const authorizeIfNeed = authenticationType => {
  if (authenticationType === 'none') return Promise.resolve()
  return import('aws-amplify').then(({ Auth }) => {
    return Auth.currentAuthenticatedUser().then(userData => userData.signInUserSession.idToken.jwtToken)
  })
}

export const fetchRunningApplication = token => dispatch => {
  return request.get(`/running_applications/${token}`, { dispatch }).then(response => {
    const features = {
      language: response.options.language,
      operator: response.options.operator,
      reset: response.options.reset,
      undo: response.options.undo,
      prefetch: response.options.prefetch,
      suggestion: response.options.suggestion,
      authenticationType: response.options.authentication_type,
      disableWebsocket: response.options.disable_websocket,
      skipCookieConsent: response.options.skip_cookie_consent,
      cookieExpirationPeriodInDays: response.options.cookie_expiration_period_in_days,
      showAdvertisement: response.options.show_advertisement,
      restrictImageFileUploading: response.options.restrict_image_file_uploading,
      keepConversationHistory: response.options.keep_conversation_history,
      enableTranslation: response.options.enable_translation,
      enableAITalk: response.options.enable_aitalk,
      speakerNames: response.custom.speaker_names,
      defaultNoticeMode: response.options.default_notice_mode,
      defaultSpeakerName: response.options.default_speaker_name,
      iconUrl: response.custom.icon_url,
      digitMaskingLength: response.options.digit_masking_length,
      callingOperatorMessage: response.custom.calling_operator_message,
      callingOperatorButton: response.custom.calling_operator_button,
      operatorTypingMessage: response.custom.operator_typing_message,
      amplifyConfiguration: response.amplify_configuration,
    }
    dispatch(setCurrentChannel(null))
    dispatch(setFeatures(features))
    return features
  })
}

export const prefetchApplication = (
  application_token,
  link_token = null,
  language = undefined
) => dispatch => {
  const params = lodash.pickBy({ link_token, language })
  const query = queryString.stringify(params)

  return request
    .get(`/running_applications/${application_token}/prefetch${query ? '?' + query : ''}`, {
      dispatch,
    })
    .then(response => {
      if (response.initial_messages) {
        dispatch(receiveMessages(null, response.initial_messages))
      }
      return response
    })
}

export const setApplicationToken = applicationToken => ({
  type: 'SET_APPLICATION_TOKEN',
  applicationToken,
})

export const setChatStatus = status => ({
  type: 'SET_CHAT_STATUS',
  status,
})

export const storeMessage = message => ({
  type: 'STORE_MESSAGE',
  message,
})

export const clearMessages = () => ({
  type: 'CLEAR_MESSAGES',
})

export const setCurrentChannel = channel_uuid => ({
  type: 'SET_CURRENT_CHANNEL',
  channel_uuid,
})

export const setFeatures = (features = {}) => ({
  type: 'SET_FEATURES',
  features,
})

export const updateCurrentChannel = features => ({
  type: 'UPDATE_CURRENT_CHANNEL',
  features,
})

const receiveMessages = (channel_uuid, messages) => ({
  type: 'RECEIVE_MESSAGES',
  channel_uuid,
  messages: messages.reverse(),
})

const getDomain = isSimulator => {
  const pattern = /https?:\/\/([^/]+)/
  let url
  if (isSimulator) {
    url = window.location.href
  } else {
    if (pattern.test(document.referrer)) {
      url = document.referrer
    } else {
      url = window.location.href
    }
  }
  return url.match(pattern)[1]
}

export const fetchChannels = (application_token, isSimulator = false) => dispatch => {
  const domain = getDomain(isSimulator)
  return request.get(`/channels/?application_token=${application_token}&domain=${domain}`, {
    dispatch,
    fetchOptions: {
      credentials: 'include',
    },
  })
}

export const connectApplication = (
  application_token,
  channel_uuid,
  authenticationType = 'none',
  isSimulator = false
) => dispatch => {
  return authorizeIfNeed(authenticationType).then(user_jwt => {
    return request
      .post('/channels/connect', {
        body: {
          application_token,
          channel_uuid,
          user_jwt,
          domain: getDomain(isSimulator),
        },
        dispatch,
        fetchOptions: {
          credentials: 'include',
        },
      })
      .then(response => {
        dispatch(setCurrentChannel(response.channel_uuid))
        if (channel_uuid && channel_uuid !== response.channel_uuid) {
          dispatch(clearMessages())
        }
        return response
      })
  })
}

export const joinApplication = (
  application_token,
  channel_uuid,
  referrer,
  no_reply = false,
  link_token = null,
  variables = undefined,
  authenticationType = 'none',
  need_join = false,
  keep_conversation_history = true,
  language = undefined
) => dispatch => {
  return authorizeIfNeed(authenticationType).then(user_jwt => {
    return request
      .post('/channels/join', {
        body: {
          application_token,
          channel_uuid,
          referrer,
          no_reply,
          link_token,
          variables,
          user_jwt,
          need_join,
          keep_conversation_history,
          language,
        },
        dispatch,
      })
      .then(response => {
        const features = {
          language: response.language,
          operator: response.operator_function,
          reset: response.reset_function,
          undo: response.undo_function,
          prefetch: response.prefetch_function,
          suggestion: response.suggestion_function,
          authenticationType: response.authentication_type,
          disableWebsocket: response.disable_websocket,
          cookieExpirationPeriodInDays: response.cookie_expiration_period_in_days,
          showAdvertisement: response.show_advertisement,
          restrictImageFileUploading: response.restrict_image_file_uploading,
          keepConversationHistory: response.keep_conversation_history,
          iconUrl: response.icon_url,
          digitMaskingLength: response.digit_masking_length,
          callingOperatorMessage: response.calling_operator_message,
          callingOperatorButton: response.calling_operator_button,
          operatorTypingMessage: response.operator_typing_message,
          enableTranslation: response.enable_translation,
          enableAITalk: response.enable_aitalk,
          speakerNames: response.speaker_names,
          defaultNoticeMode: response.default_notice_mode,
          defaultSpeakerName: response.default_speaker_name,
        }
        dispatch(setCurrentChannel(response.channel_uuid))
        dispatch(setFeatures(features))
      })
  })
}

export const restoreMessages = channel_uuid => {
  return {
    type: 'RESTORE_MESSAGES',
    channel_uuid,
  }
}

export const fetchMessages = (channel_uuid, since = null, link_token = null) => dispatch => {
  const queries = {
    since,
    link_token,
  }
  const query = lodash.map(lodash.omitBy(queries, lodash.isNull), (v, k) => `${k}=${v}`).join('&')
  return request
    .get(`/channels/${channel_uuid}/messages${query ? '?' + query : ''}`, {
      dispatch,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    })
    .then(messages => {
      dispatch(receiveMessages(channel_uuid, messages))
      return messages
    })
}

export const sendMessage = (
  channel_uuid,
  text,
  referrer,
  value,
  link_token = null,
  authenticationType = 'none',
  isSimulator = false
) => dispatch => {
  const content = { text: text, value: value }
  return sendRawMessage(
    channel_uuid,
    'text',
    content,
    referrer,
    link_token,
    null,
    authenticationType,
    isSimulator
  )(dispatch)
}

export const sendRawMessage = (
  channel_uuid,
  type,
  content,
  referrer,
  link_token = null,
  image_file = null,
  authenticationType = 'none',
  isSimulator = false
) => dispatch => {
  return authorizeIfNeed(authenticationType).then(user_jwt => {
    var message = {
      uuid: uuid.v4(),
      timestamp: moment(),
      channel_uuid: channel_uuid,
      sender_uid: '',
      sender_name: 'Guest',
      sender_type: 'client',
      type: type,
      content: content,
      temporary: true,
    }

    let body
    if (type === 'image' && image_file) {
      message.content = {
        ...message.content,
        image_url: window.URL.createObjectURL(image_file),
      }
      dispatch(storeMessage(message))

      body = new FormData()
      body.append('uuid', message.uuid)
      body.append('type', message.type)
      body.append('content', JSON.stringify(content))
      body.append('referrer', referrer)
      body.append('link_token', link_token)
      body.append('image_file', image_file)
      if (user_jwt) {
        body.append('user_jwt', user_jwt)
      }
      body.append('domain', getDomain(isSimulator))
    } else {
      if (content.text) {
        dispatch(storeMessage(message))
      }

      body = {
        uuid: message.uuid,
        type: message.type,
        content: message.content,
        referrer: referrer,
        link_token: link_token,
        user_jwt: user_jwt,
        domain: getDomain(isSimulator),
      }
    }

    return request.post(`/channels/${channel_uuid}/messages`, {
      body,
      dispatch,
      fetchOptions: {
        credentials: 'include',
      },
    })
  })
}

export const resetChannel = (channel_uuid, referrer) => dispatch => {
  if (!channel_uuid) return Promise.resolve()

  const body = {
    referrer,
  }
  return request.post(`/channels/${channel_uuid}/reset`, {
    body,
    dispatch,
  })
}

export const startBotTyping = () => ({
  type: 'START_BOT_TYPING',
})

export const stopBotTyping = () => ({
  type: 'STOP_BOT_TYPING',
})

export const preventBotTypingMessage = () => ({
  type: 'PREVENT_BOT_TYPING_MESSAGE',
})

export const allowBotTypingMessage = () => ({
  type: 'ALLOW_BOT_TYPING_MESSAGE',
})

export const startOperatorTyping = () => ({
  type: 'START_OPERATOR_TYPING',
})

export const stopOperatorTyping = () => ({
  type: 'STOP_OPERATOR_TYPING',
})

export const updateUnreadStatus = () => ({
  type: 'UPDATE_UNREAD_STATUS',
})

export const setAutoScroll = isAutoScroll => ({
  type: 'SET_AUTO_SCROLL',
  isAutoScroll,
})

export const fetchCandidates = (application_token, index_name, query, link_token) => dispatch => {
  return request
    .post('/channels/candidates', {
      body: {
        application_token,
        index_name,
        query,
        link_token,
      },
      dispatch,
    })
    .then(candidates => {
      dispatch(setCandidates(query, candidates))
    })
}

export const setCandidates = (query, candidates) => ({
  type: 'SET_CANDIDATES',
  query,
  candidates,
})

export const clearCandidates = () => ({
  type: 'CLEAR_CANDIDATES',
})

export const selectPrevCandidate = () => ({
  type: 'SELECT_PREV_CANDIDATE',
})

export const selectNextCandidate = () => ({
  type: 'SELECT_NEXT_CANDIDATE',
})

export const selectCandidate = index => ({
  type: 'SELECT_CANDIDATE',
  index,
})

export const changeNoticeMode = (applicationToken, mode) => ({
  type: 'CHANGE_NOTICE_MODE',
  applicationToken,
  mode,
})

export const changeSpeaker = (applicationToken, speaker) => ({
  type: 'CHANGE_SPEAKER',
  applicationToken,
  speaker,
})
