import { Schema, arrayOf } from 'normalizr'
const account = new Schema('accounts', {
  idAttribute: account => account.id,
})

const user = new Schema('users', {
  idAttribute: user => user.id,
})

const bot = new Schema('bots', {
  idAttribute: bot => bot.id,
})

const application = new Schema('applications', {
  idAttribute: application => application.id,
})

const domain = new Schema('domains', {
  idAttribute: domain => domain.id,
})

const topic = new Schema('topics', {
  idAttribute: topic => topic.id,
})

const flow = new Schema('flows', {
  idAttribute: flow => flow.id,
})

const faq = new Schema('faqs', {
  idAttribute: faq => faq.id,
})

const faqPdfFile = new Schema('faq_pdf_files', {
  idAttribute: faqPdfFile => faqPdfFile.id,
})

const faqCategory = new Schema('faq_categories', {
  idAttribute: faqCategory => faqCategory.id,
})

const faqItem = new Schema('faq_items', {
  idAttribute: faqItem => faqItem.id,
})

const classifierInstance = new Schema('classifier_instances', {
  idAttribute: instance => instance.id,
})

const endpoint = new Schema('endpoints', {
  idAttribute: endpoint => endpoint.id,
})

const entity = new Schema('entities', {
  idAttribute: entity => entity.id,
})

const integration = new Schema('integrations', {
  idAttribute: integration => integration.id,
})

const task = new Schema('tasks', {
  idAttribute: task => task.id,
})

const room = new Schema('rooms', {
  idAttribute: room => room.id,
})

const interruption = new Schema('interruptions', {
  idAttribute: interruption => interruption.id,
})

const sampleTemplate = new Schema('sample_templates', {
  idAttribute: sampleTemplate => sampleTemplate.id,
})

const emailAddress = new Schema('email_addresses', {
  idAttribute: emailAddress => emailAddress.id,
})

const emailDomain = new Schema('email_domains', {
  idAttribute: emailDomain => emailDomain.id,
})

const oauthClient = new Schema('oauth_clients', {
  idAttribute: oauthClient => oauthClient.id,
})

const thread = new Schema('threads', {
  idAttribute: thread => thread.uuid,
})

const predictionFailureLog = new Schema('prediction_failure_logs', {
  idAttribute: predictionFailureLog => predictionFailureLog.message_uuid,
})

const plan = new Schema('plans', {
  idAttribute: plan => plan.id,
})

const conditionGroup = new Schema('condition_groups', {
  idAttribute: conditionGroup => conditionGroup.id,
})

const learningExclusionWord = new Schema('learning_exclusion_words', {
  idAttribute: learningExclusionWord => learningExclusionWord.id,
})

const deliveryJob = new Schema('delivery_jobs', {
  idAttribute: deliveryJob => deliveryJob.id,
})

const Schemas = {
  accounts: arrayOf(account),
  account: account,
  users: arrayOf(user),
  user: user,
  bots: arrayOf(bot),
  bot: bot,
  applications: arrayOf(application),
  application: application,
  domains: arrayOf(domain),
  domain: domain,
  topics: arrayOf(topic),
  topic: topic,
  flows: arrayOf(flow),
  flow: flow,
  faqs: arrayOf(faq),
  faq: faq,
  faqPdfFiles: arrayOf(faqPdfFile),
  faqPdfFile: faqPdfFile,
  faqCategories: arrayOf(faqCategory),
  faqCategory: faqCategory,
  faqItems: arrayOf(faqItem),
  faqItem: faqItem,
  classifierInstance: classifierInstance,
  endpoints: arrayOf(endpoint),
  endpoint: endpoint,
  entities: arrayOf(entity),
  entity: entity,
  integrations: arrayOf(integration),
  integration: integration,
  tasks: arrayOf(task),
  task: task,
  rooms: arrayOf(room),
  room: room,
  interruptions: arrayOf(interruption),
  interruption: interruption,
  sampleTemplates: arrayOf(sampleTemplate),
  sampleTemplate: sampleTemplate,
  emailAddresses: arrayOf(emailAddress),
  emailAddress: emailAddress,
  emailDomains: arrayOf(emailDomain),
  emailDomain: emailDomain,
  oauthClients: arrayOf(oauthClient),
  oauthClient: oauthClient,
  threads: arrayOf(thread),
  thread: thread,
  predictionFailureLogs: arrayOf(predictionFailureLog),
  predictionFailureLog: predictionFailureLog,
  plans: arrayOf(plan),
  plan: plan,
  conditionGroups: arrayOf(conditionGroup),
  conditionGroup: conditionGroup,
  learningExclusionWords: arrayOf(learningExclusionWord),
  learningExclusionWord: learningExclusionWord,
  deliveryJobs: arrayOf(deliveryJob),
  deliveryJob: deliveryJob,
}

export default Schemas
