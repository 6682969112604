import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { abortAllRequests } from '../helpers/request'
import Stickyfill from 'stickyfilljs'
import Header from '../components/header/Header'
import Breadcrumbs from '../containers/header/Breadcrumbs'
import Footer from '../components/Footer'
import Menu from '../components/header/Menu'
import Notices from '../components/common/Notices'
import ImportantMessages from '../components/common/ImportantMessages'
import TourManager from './utils/TourManager'

import i18n from '../middleware/i18n'
import { deleteSession } from '../actions/session'
import { removeNotice } from '../actions/notice'
import { startTour } from '../actions/tour'
import { fetchRooms } from '../actions/chat'
import { setContext } from '../helpers/requestManagementAPI'
import { fetchImportantMessages } from '../actions/news'

class App extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  }
  static childContextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node,
    notices: PropTypes.array,
    location: PropTypes.object.isRequired,
    operatorBadge: PropTypes.number,
    account: PropTypes.object,
    bots: PropTypes.array,
    applications: PropTypes.array,
    importantMessages: PropTypes.array,
  }

  constructor() {
    super()
    this.state = { isDrawerMenuOpened: false }
    i18n.loadLanguages(['ja', 'en', 'zh'])
  }

  getChildContext() {
    return { t: this.props.t }
  }

  componentWillMount() {
    setContext(this.context)
  }

  componentDidMount() {
    const { store } = this.context
    const { dispatch } = this.props
    dispatch(fetchImportantMessages())

    const state = store.getState()
    if (state.session !== undefined && state.session.token) {
      dispatch(fetchRooms(state.session, { operator_state: 'calling' }))
    }

    window.addEventListener('beforeunload', () => {
      abortAllRequests()
    })

    if (window.zE) {
      // Hide Zendesk widget until user data is loaded.
      window.zE('webWidget', 'hide')
    }

    document.body.classList.add('show-cookiebot')
  }

  componentDidUpdate() {
    const state = this.context.store.getState()
    // Add sticky elements after rendering the header(Only after login).
    if (
      state.session !== undefined &&
      state.session.token &&
      document.getElementsByClassName('dm-breadcrumb').length > 0
    ) {
      Stickyfill.add(document.querySelectorAll('.dm-header, .dm-notices'))
      Stickyfill.refreshAll()
    }
  }

  handleLogout = e => {
    e.preventDefault()
    const { dispatch } = this.props
    this.context.router.push('/login')
    if (this.context.router.isActive('/login')) {
      dispatch(deleteSession())
    }
    if (window.zE) {
      // Hide Zendesk widget on logout
      window.zE('webWidget', 'hide')
    }
  }

  handleRemoveNotice = id => {
    const { dispatch } = this.props
    dispatch(removeNotice(id))
  }

  hideDrawerMenu = () => {
    this.setState({ isDrawerMenuOpened: false })
  }

  toggleDrawerMenu = e => {
    e.preventDefault()
    this.setState({ isDrawerMenuOpened: !this.state.isDrawerMenuOpened })
  }

  startTour = () => {
    this.props.dispatch(startTour())
  }

  render() {
    const state = this.context.store.getState()
    const {
      operatorBadge,
      notices,
      children,
      location,
      location: { pathname },
      account,
      bots,
      applications,
      importantMessages,
    } = this.props

    const headerClasses = ['dm-header', 'navbar']
    if (!lodash.isEmpty(importantMessages)) {
      headerClasses.push('with-important-messages')
    }
    if (state.session.token) {
      headerClasses.push('with-breadcrumbs')
    }

    let content = null
    let toggleDrawerMenu
    if (pathname === '/error' || pathname === '/restricted') {
      content = (
        <div>
          <div key="main">{children}</div>
        </div>
      )
      toggleDrawerMenu = null
    } else {
      content = (
        <div className="row">
          <div className="col-sm-3 col-md-3 col-lg-2">
            {state.session.token && (
              <Menu
                session={state.session}
                pathname={pathname}
                hideDrawerMenu={this.hideDrawerMenu}
                bots={bots}
                applications={applications}
              />
            )}
          </div>
          <div className="col-sm-9 col-md-9 col-lg-10" key="main">
            {children}
          </div>
        </div>
      )
      toggleDrawerMenu = this.toggleDrawerMenu
    }
    return (
      <div className={`react-root ${this.state.isDrawerMenuOpened ? 'is-sidebar-open' : ''}`}>
        {state.session.token && <TourManager pathname={pathname} />}
        <div className={headerClasses.join(' ')}>
          <ImportantMessages messages={importantMessages} />
          <Header
            session={state.session}
            onLogout={this.handleLogout}
            onToggleDrawerMenu={toggleDrawerMenu}
            onStartTour={this.startTour}
            operatorBadge={operatorBadge}
            account={account}
          />
          <Breadcrumbs session={state.session} location={location} />
        </div>
        <Notices notices={notices} onRemove={this.handleRemoveNotice} />

        <div className="container dm-main">
          {content}
          <div className="contents-overlay" onClick={this.toggleDrawerMenu} />
        </div>

        <Footer />
      </div>
    )
  }
}

const LocalizedApp = withTranslation()(App)

const mapStateToProps = state => {
  const account = lodash.first(Object.values(state.entities.accounts))
  const bots = lodash.filter(state.entities.bots)
  const applications = lodash.filter(state.entities.applications)
  const callingCount = lodash.filter(state.chat.rooms, { operator_state: 'calling' }).length
  const unreadCount = lodash.filter(state.chat.rooms, room => room.unread > 0).length

  return {
    notices: lodash.filter(state.notice, { options: { source: 'dashboard' } }),
    operatorBadge: callingCount + unreadCount,
    account: account,
    bots: bots,
    applications: applications,
    importantMessages: state.news.importantMessages,
  }
}

export default connect(mapStateToProps)(LocalizedApp)
