import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, Field, reduxForm, propTypes } from 'redux-form'
import { isEmail } from 'validator'
import queryString from 'query-string'

import { signUp } from '../../actions/user'
import { CheckboxField, InputField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'

const validate = data => {
  const errors = {}
  if (!data.email) {
    errors.email = 'validate.required'
  } else if (!isEmail(data.email)) {
    errors.email = 'validate.invalidEmail'
  } else if (data.email.length > 255) {
    errors.email = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  }
  if (!data.company_name) {
    errors.company_name = 'validate.required'
  } else if (data.company_name.length > 100) {
    errors.company_name = { id: 'validate.exceededMaxLength', values: { length: 100 } }
  }
  if (data.phone_number) {
    if (data.phone_number.length < 10 || data.phone_number.length > 30) {
      errors.phone_number = 'validate.invalid'
    } else if (!/^\+?(\d+-)*\d+$/.test(data.phone_number)) {
      errors.phone_number = 'validate.invalid'
    }
  }
  return errors
}

export class SignUp extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    agreed: PropTypes.bool,
  }

  componentWillMount() {
    const state = this.context.store.getState()
    const router = this.context.router

    if (state.session.token) {
      router.replace('/')
    }
  }

  handleSave = (data, dispatch) => {
    const user = {
      pending_email: data.email,
      company_name: data.company_name,
      phone_number: data.phone_number,
      partner_code: data.partner_code,
    }

    return dispatch(signUp(user)).then(() => window.location.replace('/wait_confirm.html'))
  }

  render() {
    const { t } = this.context
    const { handleSubmit, agreed, isSubmitting } = this.props

    return (
      <div className="row">
        <h2 className="black">{t('signup.title')}</h2>
        <div className="black">{t('signup.description1')}</div>
        <div className="black">{t('signup.description2')}</div>
        <div className="black">{t('signup.description3')}</div>
        <form className="text-left col-sm-8 col-md-8 mt-3" onSubmit={handleSubmit(this.handleSave)}>
          <Field
            type="hidden"
            name="partner_code"
            className="form-control dm-form-control"
            component={InputField}
          />
          <div className="dm-signup">
            <div className="form-group">
              <LabelWithTooltip htmlFor="email" className="dm-title-mini" name="signup.email" />
              <Field
                type="email"
                name="email"
                className="form-control dm-form-control"
                maxLength="255"
                component={InputField}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip htmlFor="company_name" className="dm-title-mini" name="signup.companyName" />
              <Field
                type="text"
                name="company_name"
                className="form-control dm-form-control"
                maxLength="100"
                component={InputField}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip htmlFor="phone_number" className="dm-title-mini" name="signup.phoneNumber" />
              <Field
                type="tel"
                name="phone_number"
                className="form-control dm-form-control"
                maxLength="30"
                minLength="10"
                component={InputField}
              />
            </div>
            <div className="form-inline dm-agreed">
              <Field
                type="checkbox"
                ref="agreed"
                name="agreed"
                className="form-check-input"
                component={CheckboxField}
              />
              <label
                htmlFor="agreed"
                className="dm-checkbox"
                dangerouslySetInnerHTML={{ __html: t('signup.agreed', { url: '/terms.html' }) }}
              />
            </div>
            <div className="form-group text-right">
              <button type="submit" disabled={!agreed || isSubmitting} className="btn btn-primary dm-btn">
                {t('common.sendInvitation')}
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const SignUpForm = reduxForm({
  form: 'SignUp',
  validate,
})(SignUp)

const selector = formValueSelector('SignUp')

export const mapStateToProps = state => {
  const partnerCode = queryString.parse(window.location.search).partner_code

  return {
    initialValues: {
      partner_code: partnerCode,
    },
    agreed: selector(state, 'agreed'),
  }
}

export default connect(mapStateToProps)(SignUpForm)
