import mergeWith from 'lodash/mergeWith'

const initialEntities = {
  accounts: {},
  users: {},
  applications: {},
  bots: {},
  domains: {},
  topics: {},
  endpoints: {},
  entities: {},
  faqs: {},
  faq_pdf_files: {},
  faq_categories: {},
  faq_items: {},
  integrations: {},
  tasks: {},
  interruptions: {},
  sample_templates: {},
  email_addresses: {},
  email_domains: {},
  oauth_clients: {},
  threads: {},
  prediction_failure_logs: {},
  learning_exclusion_words: {},
  plans: {},
  condition_groups: {},
  delivery_jobs: {},
}

export default (state = initialEntities, action) => {
  if (action.type === 'RESPONSE_API') {
    const newState = { ...state }
    if (!action.preserve) {
      newState[action.target] = {}
    }
    const customizer = (obj, src) => (Array.isArray(src) ? src : undefined)
    return mergeWith({}, newState, action.response.entities, customizer)
  }
  if (action.type === 'DELETE_CACHE') {
    const nextEntities = { ...state[action.target] }
    if (typeof action.id === 'number') {
      delete nextEntities[action.id]
    } else if (typeof action.id === 'object') {
      action.id.forEach(i => delete nextEntities[i])
    } else {
      for (let key in nextEntities) {
        if (nextEntities.hasOwnProperty(key)) {
          delete nextEntities[key]
        }
      }
    }
    return { ...state, [action.target]: nextEntities }
  }
  return state
}
