import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, Field, reduxForm, propTypes } from 'redux-form'

import { addNotice } from '../../actions/notice'
import { importFaq } from '../../actions/faq'
import { importFaqPdfFile } from '../../actions/faq_pdf_file'
import { fetchApplications } from '../../actions/application'
import { fetchBot } from '../../actions/bot'
import { fetchFaqItems } from '../../actions/faq_item'
import { InputField, FileField, SelectField, TextAreaField } from '../../components/common/fields/FormFields'
import Sidebar from '../../components/common/Sidebar'
import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { Simulator } from '../simulator/Simulator'
import { checkApplications } from '../../helpers/checkApplications'
import cancelUrl from '../../helpers/cancelurl'
import { downloadBlob } from '../../helpers/download'
import { lengthWithoutVariableAndUrl } from '../../helpers/validation'

const validate = data => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else {
    if (data.name.length > 255) {
      errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
  }
  if (data.type === 'pdf') {
    if (data.link && data.link.length > 255) {
      errors.link = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
    if (data.description && data.description.length > 255) {
      errors.description = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
  }

  return errors
}

export class FaqImport extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      bot_id: PropTypes.string,
      faq_id: PropTypes.string,
    }),
    applications: PropTypes.array,
    faq: PropTypes.object,
    type: PropTypes.string,
  }

  componentDidMount() {
    const { dispatch } = this.props
    const state = this.context.store.getState()
    dispatch(fetchApplications(state.session.token, { original_bot_id: this.props.faq.bot_id }))
    dispatch(fetchBot(state.session.token, this.props.params.bot_id))
    cancelUrl.setRouteLeaveHook(this)
  }

  handleSave = (data, dispatch) => {
    const state = this.context.store.getState()
    const { t, router } = this.context
    const {
      applications,
      faq,
      params: { bot_id, faq_id },
    } = this.props

    if (faq_id) {
      if (!window.confirm(t('faqCategory.overwriteCSVConfirm'))) return
    }

    if (!data.upload_file || !data.upload_file[0]) {
      dispatch(addNotice('error', t('faqCategory.noFileSelectedError')))
      return
    }

    const fileSize = data.upload_file[0].size / 1024 / 1024

    if (data.type === 'faq') {
      // Validate upload size
      if (fileSize > 3) {
        dispatch(addNotice('error', t('validate.invalidFileSize', { size: 3 })))
        return
      }

      const isNeedExpandFaqAnswer = json => {
        return dispatch(fetchFaqItems(state.session.token, json.result)).then(items => {
          const answers = lodash.flatten(
            items.result.map(id => {
              return items.entities.faq_items[id].answers
            })
          )

          return answers.some(answer => lengthWithoutVariableAndUrl(answer) > 280)
        })
      }

      const body = new FormData()
      body.append('bot_id', bot_id)
      body.append('name', data.name)
      body.append('file', data.upload_file[0])
      return dispatch(importFaq(state.session.token, body, faq.id))
        .then(json => {
          router.push({
            pathname: `/bots/${bot_id}/faqs/${json.result}`,
            state: { ignoreBlocking: true },
          })
          return json
        })
        .then(json => isNeedExpandFaqAnswer(json))
        .then(isExceeded => {
          if (isExceeded) dispatch(addNotice('warn', t('faqItem.exceededAnswer')))
        })
        .then(() => dispatch(addNotice('info', t('common.importSuccessMessage'))))
        .then(() => checkApplications(bot_id, applications, dispatch, this.context))
        .catch(() => {
          document.getElementById('upload_file').value = ''
        })
    } else if (data.type === 'pdf') {
      // Validate upload size
      if (fileSize > 40) {
        dispatch(addNotice('error', t('validate.invalidFileSize', { size: 40 })))
        return
      }

      const body = new FormData()
      body.append('bot_id', bot_id)
      body.append('name', data.name)
      body.append('file', data.upload_file[0])
      body.append('link', data.link || '')
      body.append('description', data.description || '')
      return dispatch(importFaqPdfFile(state.session.token, body))
        .then(json => {
          router.push({
            pathname: `/bots/${bot_id}/faq_pdf_files/${json.result}`,
            state: { ignoreBlocking: true },
          })
        })
        .then(() => dispatch(addNotice('info', t('common.importSuccessMessage'))))
        .then(() => checkApplications(bot_id, applications, dispatch, this.context))
        .catch(() => {
          document.getElementById('upload_file').value = ''
        })
    }
  }

  clearFile = () => {
    this.props.change('upload_file', null)
  }

  downloadFile = e => {
    e.preventDefault()

    const data = new XMLHttpRequest()
    data.open('GET', e.target.href)
    data.responseType = 'blob'
    data.onload = () => {
      // Rename the file to the specified name and download it
      // Cannot use download attribute in IE
      downloadBlob(data.response, 'faq_sample.csv')
    }

    data.send()
  }

  render() {
    const { t } = this.context
    const {
      type,
      submitting,
      handleSubmit,
      bot,
      faq,
      params: { faq_id },
    } = this.props
    const types = [
      { id: 'faq', name: t('faqpdf.faq.type') },
      { id: 'pdf', name: t('faqpdf.faqPdfFile.type') },
    ]

    return (
      <div>
        <Loader loaded={!submitting} type="show">
          <form
            className="text-left col-md-9"
            ref="upload_form"
            encType="multipart/form-data"
            onSubmit={handleSubmit(this.handleSave)}
          >
            {/* Faq name */}
            <div className="form-group">
              <LabelWithTooltip htmlFor="name" className="dm-title" name="faqpdf.name" />
              <Field
                name="name"
                readOnly={faq.name ? true : false}
                type="text"
                className="form-control dm-form-control"
                maxLength="255"
                component={InputField}
              />
              {Object.keys(faq).length > 0 && <span>{t('faqCategory.overwriteCSV')}</span>}
            </div>
            {/* select type */}
            {!faq_id && (
              <div className="form-group">
                <LabelWithTooltip htmlFor="type" className="dm-title" name="faqpdf.type" />
                <Field
                  name="type"
                  items={types}
                  valueKey="id"
                  displayKey="name"
                  className="form-control dm-form-control"
                  component={SelectField}
                  onChange={this.clearFile}
                />
              </div>
            )}
            {type === 'faq' && (
              /* upload file */
              <div className="form-group">
                <LabelWithTooltip htmlFor="upload_file" className="dm-title" name="faqCategory.data" />
                <Field
                  type="file"
                  name="upload_file"
                  className="form-control dm-form-control"
                  accept=".csv"
                  component={FileField}
                />
                <div>
                  <a href="/files/faq_sample_v4.csv" onClick={this.downloadFile}>
                    {t('faqCategory.downloadSampleCSV')}
                  </a>
                </div>
                <div className="dm-example small">
                  <table className="table table-sm table-bordered">
                    <tbody>
                      <tr className="row">
                        <td className="col-sm-4">{t('faqCategory.example.fieldName.categories')}</td>
                        <td className="col-sm-8">{t('faqCategory.example.description.categories')}</td>
                      </tr>
                      <tr className="row">
                        <td className="col-sm-4">{t('faqCategory.example.fieldName.questionName')}</td>
                        <td className="col-sm-8">{t('faqCategory.example.description.questionName')}</td>
                      </tr>
                      <tr className="row">
                        <td className="col-sm-4">{t('faqCategory.example.fieldName.questions')}</td>
                        <td className="col-sm-8">{t('faqCategory.example.description.questions')}</td>
                      </tr>
                      <tr className="row">
                        <td className="col-sm-4">{t('faqCategory.example.fieldName.keywords')}</td>
                        <td className="col-sm-8">{t('faqCategory.example.description.keywords')}</td>
                      </tr>
                      <tr className="row">
                        <td className="col-sm-4">{t('faqCategory.example.fieldName.answers')}</td>
                        <td className="col-sm-8">{t('faqCategory.example.description.answers')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {type === 'pdf' && (
              <>
                {/* upload file */}
                <div className="form-group">
                  <LabelWithTooltip htmlFor="file" className="dm-title" name="faqpdf.faqPdfFile.data" />
                  <Field
                    type="file"
                    name="upload_file"
                    className="form-control dm-form-control"
                    accept=".pdf"
                    component={FileField}
                  />
                </div>
                {/* link to original file */}
                <div className="form-group">
                  <LabelWithTooltip htmlFor="link" className="dm-title" name="faqpdf.faqPdfFile.link" />
                  <Field
                    type="text"
                    name="link"
                    className="form-control dm-form-control"
                    maxLength="255"
                    component={InputField}
                  />
                </div>
                {/* description */}
                <div className="form-group">
                  <LabelWithTooltip
                    htmlFor="description"
                    className="dm-title"
                    name="faqpdf.faqPdfFile.description"
                  />
                  <Field
                    name="description"
                    minRows={4}
                    className="form-control dm-form-control"
                    maxLength="255"
                    component={TextAreaField}
                  />
                </div>
              </>
            )}

            {/* import button */}
            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary" disabled={submitting}>
                {t('common.import')}
              </button>
            </div>
          </form>
          <Sidebar titles={[t('simulator.title')]}>
            <Simulator tabs={['chat']} bot={bot} />
          </Sidebar>
        </Loader>
      </div>
    )
  }
}

const FaqImportForm = reduxForm({
  form: 'FaqImport',
  enableReinitialize: true,
  validate,
})(FaqImport)

const selector = formValueSelector('FaqImport')

const mapStateToProps = (state, props) => {
  const applications = lodash.filter(state.entities.applications)
  const bot = state.entities.bots[props.params.bot_id] || {}
  const faq = state.entities.faqs[props.params.faq_id] || {}
  const type = selector(state, 'type')

  return {
    bot: bot,
    applications,
    faq,
    type,
    initialValues: { ...faq, type: 'faq' },
  }
}

export default connect(mapStateToProps)(FaqImportForm)
