import 'core-js/proposals'
import 'core-js/stable'
import 'react-app-polyfill/ie11'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { Router, Route, Redirect, browserHistory, IndexRoute, IndexRedirect } from 'react-router'
import { Provider } from 'react-redux'
import './middleware/i18n'
import App from './containers/App'
import Login from './containers/Login'
import SelectAccount from './containers/SelectAccount'
import RequireLogin from './containers/RequireLogin'
import Dashboard from './containers/Dashboard'
import AccountEdit from './containers/account/AccountEdit'
import EmailAddressIndex from './containers/email_address/EmailAddressIndex'
import EmailAddressConfirm from './containers/email_address/EmailAddressConfirm'
import OAuthClientIndex from './containers/oauth_client/OAuthClientIndex'
import OAuthClientEdit from './containers/oauth_client/OAuthClientEdit'
import PlanUpgrade from './containers/account/PlanUpgrade'
import UserIndex from './containers/user/UserIndex'
import UserEdit from './containers/user/UserEdit'
import BotIndex from './containers/bot/BotIndex'
import BotEdit from './containers/bot/BotEdit'
import BotCreate from './containers/bot/BotCreate'
import BotImport from './containers/bot/BotImport'
import BotImportTopics from './containers/bot/BotImportTopics'
import ApplicationIndex from './containers/application/ApplicationIndex'
import ApplicationEdit from './containers/application/ApplicationEdit'
import TopicEdit from './containers/topic/TopicEdit'
import EndpointIndex from './containers/endpoint/EndpointIndex'
import EndpointEdit from './containers/endpoint/EndpointEdit'
import EntityEdit from './containers/entity/EntityEdit'
import FaqEdit from './containers/faq/FaqEdit'
import FaqImport from './containers/faq/FaqImport'
import FaqItemEdit from './containers/faq/FaqItemEdit'
import FaqPdfFileEdit from './containers/faq/FaqPdfFileEdit'
import InquiryImport from './containers/faq/generator/InquiryImport'
import InquiryGroups from './containers/faq/generator/InquiryGroups'
import GroupDetail from './containers/faq/generator/GroupDetail'
import ChatSimulator from './containers/chat/ChatSimulator'
import AuthenticationPopup from './containers/chat/AuthenticationPopup'
import EmbeddedChatWrapper from './containers/chat/EmbeddedChatWrapper'
import EmbeddedChatCallback from './containers/chat/EmbeddedChatCallback'
import Operator from './containers/chat/Operator'
import IntegrationEdit from './containers/integration/IntegrationEdit'
import TaskEdit from './containers/task/TaskEdit'
import PnpTopicEdit from './containers/pnp_topic/PnpTopicEdit'
import SignUp from './containers/user/SignUp'
import UserConfirm from './containers/user/UserConfirm'
import EmailConfirm from './containers/user/EmailConfirm'
import ForgotPassword from './containers/user/ForgotPassword'
import ResetPassword from './containers/user/ResetPassword'
import ChangePassword from './containers/user/ChangePassword'
import ApplicationUsage from './containers/analytics/ApplicationUsage'
import ApplicationUsageIndex from './containers/analytics/ApplicationUsageIndex'
import DeliveryIndex from './containers/delivery/DeliveryIndex'
import DeliveryJobIndex from './containers/delivery/DeliveryJobIndex'
import DeliveryJobDetail from './containers/delivery/DeliveryJobDetail'
import DeliveryJobEdit from './containers/delivery/DeliveryJobEdit'
import OAuthCallback from './containers/oauth/OAuthCallback'
import OperationHistory from './containers/administration/OperationHistory'
import ScriptLoader from './components/common/ScriptLoader'
import Error from './components/common/Error'
import { initialize, createStore, resetNoticesIfTransition } from './common'

initialize()
const store = createStore()

render(
  <Suspense fallback={<ScriptLoader />}>
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="/" component={App} onChange={resetNoticesIfTransition(store.dispatch)}>
          <Route path="/login" component={Login} />
          <Route path="/select_account" component={SelectAccount} />
          <Route path="/signup" component={SignUp} />
          <Route path="/confirm/:confirmation_token" component={UserConfirm} />
          <Route path="/confirm/email/:confirmation_token" component={EmailConfirm} />
          <Route path="/email_addresses/confirm/:confirmation_token" component={EmailAddressConfirm} />
          <Route path="/oauth/callback" component={OAuthCallback} />
          <Route path="/forgot_password" component={ForgotPassword} />
          <Route path="/reset_password/:reset_token" component={ResetPassword} />
          <Route path="/error" component={Error} />
          <Route component={RequireLogin}>
            <IndexRoute component={Dashboard} roles={'all'} />
            <Route path="/bots" roles={['owner', 'writer', 'readonly']}>
              <IndexRoute component={BotIndex} />
              <Route path="create" component={BotCreate} />
              <Route path="new" component={BotEdit} />
              <Route path="import" component={BotImport} />
              <Route path=":id">
                <IndexRoute component={BotEdit} />
                <Route path="import" component={BotImportTopics} />
                <Route path=":type" component={BotEdit} />
              </Route>
            </Route>
            <Route path="/bots/:bot_id/topics" roles={['owner', 'writer', 'readonly']}>
              <Route path="new" component={TopicEdit} />
              <Route path=":id" component={TopicEdit} />
            </Route>
            <Route path="/bots/:bot_id/faqs" roles={['owner', 'writer', 'readonly']}>
              <Route path="new" component={FaqEdit} />
              <Route path="import" component={FaqImport} />
              <Route path=":faq_id">
                <IndexRoute component={FaqEdit} />
                <Route path="import" component={FaqImport} />
                <Route path="items">
                  <IndexRedirect to="/" />
                  <Route path="new" component={FaqItemEdit} />
                  <Route path=":id" component={FaqItemEdit} />
                </Route>
              </Route>
            </Route>
            <Route path="/bots/:bot_id/faq_pdf_files" roles={['owner', 'writer', 'readonly']}>
              <Route path=":faq_pdf_file_id" component={FaqPdfFileEdit} />
            </Route>
            <Route
              path="/bots/:bot_id/faqs/:faq_id/generator"
              permissions={['feature_faq_generator']}
              roles={['owner', 'writer', 'readonly']}
            >
              <IndexRedirect to="/" />
              <Route path="import" component={InquiryImport} />
              <Route path="groups" component={InquiryGroups} />
              <Route path="groups/:group_id" component={GroupDetail} />
            </Route>
            <Route
              path="/bots/:bot_id/integrations"
              permissions={['feature_integration']}
              roles={['owner', 'writer', 'readonly']}
            >
              <Route path="new" component={IntegrationEdit} />
              <Route path=":id" component={IntegrationEdit} />
            </Route>
            <Route
              path="/bots/:bot_id/tasks"
              permissions={['feature_task']}
              roles={['owner', 'writer', 'readonly']}
            >
              <Route path="new" component={TaskEdit} />
              <Route path=":id" component={TaskEdit} />
            </Route>
            <Route path="/bots/:bot_id/entities" roles={['owner', 'writer', 'readonly']}>
              <Route path="new" component={EntityEdit} />
              <Route path=":id" component={EntityEdit} />
            </Route>
            <Route path="/bots/:bot_id/pnp_topics" permissions={['feature_line_pnp']} roles={['owner']}>
              <Route path="new" component={PnpTopicEdit} />
              <Route path=":id" component={PnpTopicEdit} />
            </Route>
            <Route path="/applications" roles={['owner', 'writer', 'readonly']}>
              <IndexRoute component={ApplicationIndex} />
              <Route path="new" component={ApplicationEdit} />
              <Route path=":id" component={ApplicationEdit} />
            </Route>
            <Route path="/endpoints" permissions={['feature_endpoint']} roles={['owner']}>
              <IndexRoute component={EndpointIndex} />
              <Route path="new" component={EndpointEdit} />
              <Route path=":id" component={EndpointEdit} />
            </Route>
            <Route path="/operator" permissions={['feature_operator']} roles={'all'}>
              <IndexRoute component={Operator} />
              <Route path=":id" component={Operator} />
            </Route>
            <Route path="/chat" roles={['owner', 'writer', 'readonly']}>
              <IndexRoute component={ChatSimulator} />
            </Route>
            <Route
              path="/analytics"
              permissions={['feature_analytics']}
              roles={['owner', 'writer', 'readonly']}
            >
              <IndexRoute component={ApplicationUsageIndex} />
              <Route path=":id/usage" component={ApplicationUsage} />
            </Route>
            <Route
              path="/delivery"
              permissions={['feature_push_api']}
              roles={['owner', 'writer', 'readonly']}
            >
              <IndexRoute component={DeliveryIndex} />
              <Route path=":application_id/jobs">
                <IndexRoute component={DeliveryJobIndex} />
                <Route path="new" component={DeliveryJobEdit} />
                <Route path=":id" component={DeliveryJobDetail} />
                <Route path=":id/copy" component={DeliveryJobEdit} />
              </Route>
            </Route>
            <Route path="/account" roles={['owner']}>
              <IndexRoute component={AccountEdit} />
              <Route path="upgrade" component={PlanUpgrade} />
            </Route>
            <Route path="/users">
              <IndexRoute component={UserIndex} roles={['owner']} />
              <Route path="new" component={UserEdit} roles={['owner']} />
              <Route path=":id" component={UserEdit} roles={'all'} />
            </Route>
            <Route path="/email_addresses" permissions={['feature_integration']} roles={['owner']}>
              <IndexRoute component={EmailAddressIndex} />
            </Route>

            <Route
              path="/oauth_clients"
              permissions={['feature_integration', 'feature_oauth']}
              roles={['owner']}
            >
              <IndexRoute component={OAuthClientIndex} />
              <Route path="new" component={OAuthClientEdit} />
              <Route path=":id" component={OAuthClientEdit} />
            </Route>
            <Route
              path="/operation_histories"
              permissions={['feature_operation_history']}
              roles={['owner']}
              component={OperationHistory}
            />
            <Route path="/change_password" component={ChangePassword} roles={'all'} />
          </Route>
        </Route>
        <Route path="/embedded">
          <IndexRoute component={EmbeddedChatWrapper} />
          <Route path="callbacks/:token" component={EmbeddedChatCallback} />
          <Route path="authentication/:token" component={AuthenticationPopup} />
        </Route>
        <Redirect from="*" to="/" />
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById('root')
)
